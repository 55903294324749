export const downloadFile = (
  blob: Blob,
  fileName: string = 'exported_file_name',
  fileFormat: string = 'xlsx'
) => {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')

  //@ts-ignore
  a.style = 'display: none'
  a.href = url
  a.download = `${fileName}.${fileFormat}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}
