import { ErrorObject } from '../modules/settings/core/_models'
import Swal from 'sweetalert2'

export const handleAPIErrors = (errors: Partial<ErrorObject>, status: number) => {
  if (status !== 200) {
    if (Object.entries(errors).length) {
      let errorFromAPI = ''

      Object.entries(errors).forEach(([key, value]) => {
        errorFromAPI += `<br/ ><span><strong>${key}</strong>: ${value}</span>`
      })

      Swal.fire('Ошибка', `Произошла ошибка: ${errorFromAPI}`, 'error')
    }

    return true
  }
}
