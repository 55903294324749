export const getDefaultDateRange = () => {
  const startDate = new Date()
  startDate.setDate(1)
  startDate.setHours(9)
  startDate.setMinutes(0)
  startDate.setSeconds(0)

  const endDate = new Date()
  endDate.setHours(18)
  endDate.setMinutes(0)
  endDate.setSeconds(0)

  return {
    startDate,
    endDate,
  }
}
