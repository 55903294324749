import DatePicker from 'react-datepicker'
import { ru } from 'date-fns/locale'

import { getMoscowDateTime } from '../../../utils/getMoscowDateTime'

import 'react-datepicker/dist/react-datepicker.css'
export interface DateFilterObject {
  dateStart: Date
  dateEnd: Date
}

interface IDateTimeRangeFilterProps {
  filter: any
  updateTime: (filterData: DateFilterObject) => void
  hideRanges?: boolean
  className?: string
}

const DateTimeRangeFilter = ({
  updateTime,
  hideRanges,
  filter,
  className,
}: IDateTimeRangeFilterProps) => {
  const input = (
    <input
      className='form-control form-control-solid ps-12 flatpickr-input'
      placeholder='Дата'
      autoComplete='off'
      type='text'
    />
  )

  const updateRangeByType = (type: string): void => {
    const now = new Date()
    const shift = now.getHours() >= 8 && now.getHours() < 20 ? 'day' : 'night'
    let from = new Date()
    let to = new Date()

    switch (type) {
      case 'currentShift':
        if (shift === 'day') from.setHours(0, 0, 0, 0)
        else {
          if (now.getHours() < 20) {
            from = new Date(Date.now() - 24 * 60 * 60 * 1000)
          }

          from.setHours(20, 0, 0, 0)
        }

        break
      case 'yesterdayShift':
        if (shift === 'day') {
          //Текущая день, нам нужен вчерашний вечер
          from = new Date(Date.now() - 24 * 60 * 60 * 1000)
          from.setHours(0, 0, 0, 0)

          to.setHours(0, 0, 0, 0)
        } else {
          //Текущая вечерная, нам нужен сегодняшнее утро
          if (now.getHours() < 20) {
            //Ночь с 00:00 - 23:59
            from = new Date(Date.now() - 24 * 60 * 60 * 1000)
            to = new Date(Date.now() - 24 * 60 * 60 * 1000)
          }

          from.setHours(8, 0, 0, 0)
          to.setHours(20, 0, 0, 0)
        }

        break
      case 'currentShiftFullDay':
        from = new Date(getMoscowDateTime().getTime())
        to = new Date(getMoscowDateTime().getTime() + 24 * 60 * 60 * 1000)

        from.setHours(0, 0, 0, 0)
        to.setHours(0, 0, 0, 0)

        break
      case 'yesterdayShiftFullDay':
        from = new Date(getMoscowDateTime().getTime() - 24 * 60 * 60 * 1000)
        to = new Date(getMoscowDateTime().getTime())

        from.setHours(0, 0, 0, 0)
        to.setHours(0, 0, 0, 0)

        break
      case '24hours':
        from = new Date(getMoscowDateTime().getTime() - 24 * 60 * 60 * 1000)

        break
      case '7days':
        from = new Date(Date.now() - 24 * 7 * 60 * 60 * 1000)
        from.setHours(8, 0, 0, 0)

        break
      case '30days':
        from = new Date(Date.now() - 24 * 30 * 60 * 60 * 1000)
        from.setHours(8, 0, 0, 0)

        break
      case 'today':
        from.setHours(8, 0, 0, 0)

        break
      case 'yesterday':
        from = new Date(Date.now() - 24 * 60 * 60 * 1000)
        from.setHours(8, 0, 0, 0)

        to.setHours(8, 0, 0, 0)

        break
      case 'currentPeriod':
        from.setHours(8, 0, 0, 0)

        if (new Date().getDate() >= 15) {
          from.setDate(15)
        } else {
          from.setDate(1)
        }

        break
      default:
        break
    }

    updateTime({
      dateStart: from,
      dateEnd: to,
    })
  }

  return (
    <div className={className}>
      <div className='row g-8'>
        <div className='col-xxl-12'>
          <div className='row g-8'>
            <div className='col-lg-5'>
              <label className='fs-6 form-label fw-bolder text-dark d-block'>Дата с </label>

              <label>
                <div className='position-relative d-flex align-items-center'>
                  <div className='symbol symbol-20px me-4 position-absolute ms-4 calendar-icon-datepicker'>
                    <i className='fa fa-calendar-alt'></i>
                  </div>
                  <DatePicker
                    selected={filter.dateStart}
                    onChange={(date: Date) =>
                      updateTime({
                        dateStart: date,
                        dateEnd: filter.dateEnd,
                      })
                    }
                    customInput={input}
                    locale={ru}
                    showTimeInput
                    dateFormat='dd.MM.yyyy HH:mm'
                  />
                </div>
              </label>
            </div>
            <div className='col-lg-5'>
              <label className='fs-6 form-label fw-bolder text-dark d-block'>Дата по </label>

              <label>
                <div className='position-relative d-flex align-items-center'>
                  <div className='symbol symbol-20px me-4 position-absolute ms-4 calendar-icon-datepicker'>
                    <i className='fa fa-calendar-alt'></i>
                  </div>
                  <DatePicker
                    selected={filter.dateEnd}
                    onChange={(date: Date) =>
                      updateTime({
                        dateStart: filter.dateStart,
                        dateEnd: date,
                      })
                    }
                    customInput={input}
                    locale={ru}
                    showTimeInput
                    dateFormat='dd.MM.yyyy HH:mm'
                  />
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      {hideRanges ? null : (
        <div className='row mt-2'>
          <div className='col-lg-12 text-left'>
            <div className='timeGroupButton'>
              <button
                className='btn py-1 px-2 mb-2 btn-sm btn-secondary'
                onClick={() => updateRangeByType('currentShift')}
              >
                Текущая смена
              </button>
              <button
                className='btn py-1 px-2 mb-2 btn-sm btn-secondary'
                onClick={() => updateRangeByType('yesterdayShift')}
              >
                Предыдущая смена
              </button>
              <button
                className='btn py-1 px-2 mb-2 btn-sm btn-info'
                onClick={() => updateRangeByType('24hours')}
              >
                24 часа
              </button>
              <button
                className='btn py-1 px-2 mb-2 btn-sm btn-primary'
                onClick={() => updateRangeByType('7days')}
              >
                7 дней
              </button>
              <button
                className='btn py-1 px-2 mb-2 btn-sm btn-primary'
                onClick={() => updateRangeByType('30days')}
              >
                30 дней
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DateTimeRangeFilter
