import axios from 'axios'
import { API_URL } from '../../../../constants'
import { IGeneralErrorObject, IRequisite } from '../../../../types'

export const postEnableReservation = (id: string) =>
  axios.post<{
    requisite: IRequisite
    success: boolean
    errors: Partial<IGeneralErrorObject>
  }>(`${API_URL}/api/internal/requisite/toggle-enable-reservation/${id}`)
