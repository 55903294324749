import { RequisiteEdit } from '../../../core/_models'

export const cleanUpRequisite = (defaultRequisite: RequisiteEdit, requisiteData: RequisiteEdit) => {
  requisiteData['cashBoxId'] = requisiteData.cashBox.id

  for (const key in requisiteData) {
    if (!defaultRequisite.hasOwnProperty(key)) {
      delete requisiteData[key as keyof RequisiteEdit]
    }
  }

  return requisiteData
}
