import DateTimeRangeFilter, {
  DateFilterObject,
} from '../../../../common/filter/DateTimeRangeFilter'
import { IFiltersProps } from './Filters.types'

export const Filters = ({
  initialFilter,
  updateFilter,
  callback,
  hasExport,
  onExport,
}: IFiltersProps) => {
  const updateTime = (filterData: DateFilterObject) => {
    updateFilter({
      ...initialFilter,
      dateStart: filterData.dateStart,
      dateEnd: filterData.dateEnd,
    })
  }

  return (
    <>
      <div className='d-flex flex-column flex-md-row flex-lg-column flex-xxl-row gap-4 align-items-center'>
        <DateTimeRangeFilter
          filter={initialFilter}
          updateTime={updateTime}
          hideRanges
          className='w-100'
        />
        <div className='d-flex flex-column flex-sm-row flex-md-column flex-lg-row w-100 gap-4 align-items-md-center'>
          <button className='btn btn-success' onClick={() => callback()}>
            Фильтровать
          </button>

          <button className='btn btn-primary' onClick={() => callback(true)}>
            Сбросить фильтр
          </button>

          {hasExport && (
            <button className='btn btn-primary' onClick={() => onExport?.()}>
              Экспортировать
            </button>
          )}
        </div>
      </div>
    </>
  )
}
