import { ChangeEvent, useRef, useState } from 'react'
import { SelectData } from '../../../common/filter/models'
import TicketConfirmedFilter from '../../../common/filter/TicketConfirmedFilter'

const ActiveTicketsFilter = ({
  initialFilter,
  updateFilters,
  callback,
}: {
  initialFilter: any
  updateFilters: (key: any) => void
  callback: (page: number, param2?: boolean | undefined) => void
}) => {
  const [searchDebounce, setSearchDebounce] = useState<any>(null)
  const searchMerchantRef = useRef<HTMLInputElement>(null)

  const onChangeSelect = (selectData: SelectData) => {
    updateFilters((prevState: any) => ({
      ...prevState,
      euConfirmed: selectData.value === 'Confirmed',
    }))
  }

  const onSearchMerchantChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce)
    const newTimer = setTimeout(() => {
      updateFilters({
        ...initialFilter,
        ...(e.target.value && { transactionId: e.target.value }),
      })
    }, 500)
    setSearchDebounce(newTimer)
  }

  return (
    <div className='row'>
      <div className='col-lg-5'>
        <div className='row align-items-md-center'>
          <TicketConfirmedFilter
            changeSelect={onChangeSelect}
            value={initialFilter.euConfirmed ? 'Confirmed' : 'Pending'}
          />
        </div>

        <div className='mt-5'>
          <div className='position-relative '>
            <div className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute pt-4 ms-3'>
              <i className='fa fa-search'></i>
            </div>
            <input
              ref={searchMerchantRef}
              type='text'
              className='form-control form-control-solid ps-10 pt-3 pb-2'
              onChange={onSearchMerchantChange}
              placeholder='Поиск по "Мерчант ID": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
            />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-sm-row flex-lg-column flex-xxl-row col-lg-4 gap-4 mt-4 mt-lg-0 align-items-md-start'>
        <button className='btn btn-success' onClick={() => callback(1)}>
          Фильтровать
        </button>
        <button
          className='btn btn-primary'
          onClick={() => {
            if (searchMerchantRef.current) {
              searchMerchantRef.current.value = ''
            }
            callback(1, true)
          }}
        >
          Сбросить фильтр
        </button>
      </div>
    </div>
  )
}

export default ActiveTicketsFilter
