import { useEffect, useState } from 'react'
import Select from 'react-select'

import { SelectOption, SelectData } from './models'
import { getListCashBoxesForSelect } from '../core/_requests'
import { SelectStyles } from '../../../components/Select'
import { ICashBox } from '../../../types'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'cashBox'

const CashBoxFilter = ({
  changeSelect,
  isMultiple,
  value,
}: {
  changeSelect: (selectData: SelectData) => void
  initialFilter?: Array<number>
  isMultiple?: boolean
  value: number[]
}) => {
  const { mode: theme } = useThemeMode()
  const [options, setOptions] = useState<Array<SelectOption>>([])
  const [loading, setLoading] = useState(true)

  const updateListCashBoxes = async () => {
    let response = await getListCashBoxesForSelect()

    if (response.data.success && response.data.cashBoxes) {
      let cashBoxes: Array<SelectOption> = []

      response.data.cashBoxes.forEach((value: ICashBox) => {
        const item = {
          value: value.id,
          label: value.title,
        }

        cashBoxes.push(item)
      })

      setOptions(cashBoxes)
      setLoading(false)
    }
  }

  useEffect(() => {
    updateListCashBoxes()
  }, [])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? []
      : !isMultiple
      ? [selectedData.value]
      : selectedData.map((item: SelectOption) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return loading ? (
    <>Загрузка касс...</>
  ) : (
    <Select
      value={options.filter((o: SelectOption) => value?.find((sv) => sv === o.value)) || ''}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите кассу'}
      styles={SelectStyles(theme)}
    />
  )
}

export default CashBoxFilter
