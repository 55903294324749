import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import SettingsPage from '../modules/settings'
import { Logs } from '../modules/Logs'
import DashboardPage from '../modules/base/DashboardPage'
import StaffPage from '../modules/staff/StaffPage'
import PaymentTicketsPage from '../modules/payment-tickets/PaymentTicketsPage'
import PayoutTicketsPage from '../modules/payout-tickets/PayoutTicketsPage'
import ReportsPage from '../modules/reports'
import ClientsPage from '../modules/clients'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='/auth' element={<Navigate to='/dashboard' />} />
        <Route path='/staff/*' element={<StaffPage />} />
        <Route path='/settings/*' element={<SettingsPage />} />
        <Route path='/logs/*' element={<Logs />} />
        <Route path='payment-tickets/*' element={<PaymentTicketsPage />} />
        <Route path='payout-tickets/*' element={<PayoutTicketsPage />} />
        <Route path='/dashboard' element={<DashboardPage />} />
        <Route path='/reports/*' element={<ReportsPage />} />
        <Route path='/client/*' element={<ClientsPage />} />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export { PrivateRoutes }
