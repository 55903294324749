import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { useFormik } from 'formik'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { SelectOptionString } from '../../app/common/filter/models'
import { INewTicket } from './NewTicket.types'
import { DEFAULT_NEW_TICKET_OBJ, NEW_TICKET_SCHEMA } from './consts'
import { getListMerchantsForSelect } from '../../app/common/core/_requests'
import { Merchant } from '../../app/common/core/_models'
import { KTIcon } from '../../_metronic/helpers'
import { getListRequisites } from '../../app/modules/settings/core/_requests'
import {
  initTicketAPI,
  uploadTicketFilePost,
} from '../../app/modules/payment-tickets/core/_requests'
import { ROLE, useAuth } from '../../app/modules/auth'
import { useThemeMode } from '../../_metronic/partials'
import { IsGranted } from '../../app/modules/auth/PrivateRoute'
import { IRequisite } from '../../types'

const AppSwal = withReactContent(Swal)

export const NewTicket = (props: any) => {
  const { mode } = useThemeMode()

  const { currentUser } = useAuth()

  const [showAddTicketModal, setShowAddTicketModal] = useState(false)
  const [merchants, setMerchants] = useState<Array<SelectOptionString>>([])
  const [loadingMerchants, setLoadingMerchants] = useState(false)
  const [requisites, setRequisites] = useState<Array<IRequisite>>([])
  const [loadingRequisites, setLoadingRequisites] = useState(false)
  const [loading, setLoading] = useState(false)

  const [searchLength, setSearchLength] = useState(0)

  const [file, setFile] = useState<any>(null)

  const initialValues: INewTicket = { ...DEFAULT_NEW_TICKET_OBJ }
  const formik = useFormik<INewTicket>({
    initialValues,
    validationSchema: NEW_TICKET_SCHEMA,
    onSubmit: (values) => {
      addTicket(values)
    },
    enableReinitialize: true,
  })

  const keysMatch = IsGranted([ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR], currentUser)
    ? ['cardNumber']
    : ['name', 'cardNumber', 'phone', 'paymentMethod', 'requisiteType', 'cashBox.title']
  const requisiteInputPlaceholder = IsGranted(
    [ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
    currentUser
  )
    ? 'Ревизит по номеру карты - минимум 4 символа'
    : 'Ревизит по имени/платежному методу/кассе/типу реквизита/номеру карты/номеру телефона - минимум 2 символа'

  const requisiteInputResultKey = IsGranted(
    [ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
    currentUser
  )
    ? 'cardNumber'
    : 'name'

  const fetchMerchants = async () => {
    setLoadingMerchants(true)

    try {
      const response = await getListMerchantsForSelect(currentUser)

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data.merchants) {
        const merchants: Array<SelectOptionString> = []

        response.data.merchants.forEach((value: Merchant) => {
          const item = {
            value: value.token,
            label: value.title,
          }

          merchants.push(item)
        })

        setMerchants(merchants)
        setLoadingMerchants(false)
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  const fetchRequisites = async () => {
    setLoadingRequisites(true)

    try {
      const requisitesPageOne = await getListRequisites({ page: 1, enabled: true })
      const requisitesPageTwo = await getListRequisites({ page: 2, enabled: true })

      if (!requisitesPageOne.data) {
        return false
      }

      if (
        requisitesPageOne.data.success &&
        requisitesPageOne.data.requisites &&
        requisitesPageTwo.data.success &&
        requisitesPageTwo.data.requisites
      ) {
        setRequisites([...requisitesPageOne.data.requisites, ...requisitesPageTwo.data.requisites])
        setLoadingRequisites(false)
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  useEffect(() => {
    if (showAddTicketModal) {
      fetchMerchants()
      fetchRequisites()
    }
  }, [showAddTicketModal])

  const onClickHandler = () => {
    setShowAddTicketModal(true)
  }

  const onCancelHandler = (e: SyntheticEvent) => {
    formik.handleReset(e)
    setShowAddTicketModal(false)
  }

  const addTicket = async (params: any) => {
    setLoading(true)

    const formData = new FormData()

    if (file) {
      formData.append('file', file)
    }

    try {
      const newTicketRes = await initTicketAPI(params)

      if (!newTicketRes.data) {
        return false
      }

      let uploadRes = null

      if (file) {
        uploadRes = await uploadTicketFilePost(formData, newTicketRes.data.ticket.token)
      }

      if (newTicketRes.data.success && newTicketRes.data) {
        formik.resetForm()
        setFile(null)
        setShowAddTicketModal(false)

        if (uploadRes?.data.errors) {
          props.callback()
        } else {
          AppSwal.fire({
            title: `Новый тикет '${newTicketRes.data.ticket.token}' успешно добавлен!`,
            timer: 1500,
            willClose: () => {
              props.callback()
            },
          })
        }
      }

      setLoading(false)
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  //@ts-ignore
  const handleOnSelect = (item) => {
    formik.setFieldValue('requisiteId', item.id)
  }

  //@ts-ignore
  const formatResult = (item) => {
    return (
      <>
        <div
          className='d-flex gap-2 fs-6'
          style={{
            cursor: 'pointer',
            borderBottom: '1px solid #cacaca',
          }}
        >
          {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
            <>
              <span style={{ width: '20px' }}>{item.id}</span>
              <div className='d-inline-flex'>
                <span
                  className='d-block text-truncate'
                  style={{
                    width: '120px',
                  }}
                >
                  {item.name}
                </span>
              </div>
            </>
          )}

          <div className='d-inline-flex'>
            <span
              className='d-block text-truncate'
              style={{
                width: IsGranted([ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR], currentUser)
                  ? '300px'
                  : '120px',
              }}
            >
              {item.paymentMethods &&
                item.paymentMethods.length &&
                item.paymentMethods.map((pm: any) => pm?.type || '').join(', ')}
            </span>
          </div>
          {IsGranted([ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR], currentUser) && (
            <div className='d-inline-flex'>
              <span className='d-block text-truncate'>{item.cardNumber}</span>
            </div>
          )}

          {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
            <>
              <div className='d-inline-flex'>
                <span
                  className='d-block text-truncate'
                  style={{
                    width: '120px',
                  }}
                >
                  {item.requisiteType}
                </span>
              </div>
              <div className='d-inline-flex'>
                <span
                  className='d-block text-truncate'
                  style={{
                    width: '80px',
                  }}
                >
                  {item.cashBox.title}
                </span>
              </div>
            </>
          )}
        </div>
      </>
    )
  }

  const onSearch = (e: string) => {
    setSearchLength(e.length)
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.files?.[0])
  }

  return (
    <>
      <button className='btn btn-success' onClick={onClickHandler}>
        Добавить новый тикет
      </button>

      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={showAddTicketModal}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Добавить новый тикет</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => {
              setShowAddTicketModal(false)
              formik.resetForm()
            }}
          >
            <KTIcon className='fs-1' iconName='cross' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Сумма</label>

                <div className='col-lg-9 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Сумма'
                    {...formik.getFieldProps('amount')}
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Реквизит</label>

                <div className='col-lg-9 fv-row'>
                  {loadingRequisites ? (
                    'Загрузка реквизитов'
                  ) : (
                    <>
                      <ReactSearchAutocomplete
                        items={requisites}
                        fuseOptions={{
                          shouldSort: true,
                          threshold: 0,
                          location: 0,
                          distance: 0,
                          ignoreLocation: true,
                          minMatchCharLength: 2,
                          keys: keysMatch,
                        }}
                        resultStringKeyName={requisiteInputResultKey}
                        inputDebounce={300}
                        onSearch={onSearch}
                        onSelect={handleOnSelect}
                        showIcon={false}
                        formatResult={formatResult}
                        placeholder={requisiteInputPlaceholder}
                        styling={{
                          borderRadius: '6px',
                          ...(mode === 'dark' && {
                            backgroundColor: 'var(--bs-gray-100)',
                            border: '1px solid var(--bs-gray-100)',
                            color: 'var(--bs-gray-700)',
                            hoverBackgroundColor: 'var(--bs-gray-200)',
                            lineColor: 'var(--bs-gray-100)',
                          }),
                        }}
                        showNoResults={searchLength > 1}
                        showNoResultsText={searchLength < 2 ? '' : 'Ничего не найдено.'}
                        className='autocomplete-input'
                      />
                      {formik.touched.requisiteId && formik.errors.requisiteId && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.requisiteId}</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6'>Мерчант</label>

                <div className='col-lg-9 fv-row'>
                  {loadingMerchants ? (
                    'Загрузка мерчантов'
                  ) : (
                    <>
                      <select
                        className='form-select form-select-solid form-select-lg fw-bold'
                        {...formik.getFieldProps('merchantToken')}
                      >
                        <option value={''}>Не выбрано</option>
                        {merchants.map((merchant, index) => (
                          <option value={merchant.value} key={index}>
                            {merchant.label}
                          </option>
                        ))}
                      </select>
                      {formik.touched.merchantToken && formik.errors.merchantToken && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.merchantToken}</div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Файл</label>

                <div className='col-lg-9 fv-row'>
                  <input
                    type='file'
                    className='form-control form-control-lg form-control-solid'
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn' onClick={onCancelHandler}>
                Отмена
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Добавить тикет'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Идёт добавление тикета...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
