import styles from './Table.module.css'

interface ITableProps {
  children?: React.ReactNode
}

export const Table = ({ children }: ITableProps) => {
  return (
    <div className='table-responsive'>
      <table className={`table table-row-bordered table-striped align-middle gy-3 ${styles.Table}`}>
        {children}
      </table>
    </div>
  )
}
