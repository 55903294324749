import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { ROLE } from '../auth'
import { PrivateRoute } from '../auth/PrivateRoute'
import ReportTicketsPage from './components/ReportTicketsPage'

const ReportsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='/payment'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_SENIOR_OPERATOR,
              ]}
            >
              <ReportTicketsPage key={'payment'} typeReport={'payment'} />
            </PrivateRoute>
          }
        />
        <Route
          path='/payout'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_SENIOR_OPERATOR,
              ]}
            >
              <ReportTicketsPage key={'payout'} typeReport={'payout'} />
            </PrivateRoute>
          }
        />
        <Route index element={<Navigate to='/payment' />} />
      </Route>
    </Routes>
  )
}

export default ReportsPage
