import { Link } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import { IsGranted } from '../../../app/modules/auth/PrivateRoute'
import { ROLE, useAuth } from '../../../app/modules/auth'
import { IClientItem } from '../../../app/modules/clients/core/_models'

export const ClientItem = (props: {
  client: IClientItem
  setClient: (client: IClientItem) => void
  chooseClient: boolean
  showFlagColumn?: boolean
}) => {
  const client = props.client
  const { currentUser } = useAuth()

  return (
    <tr>
      {props.showFlagColumn ? (
        <td>
          {
            <img
              src={toAbsoluteUrl(`/media/flags/${client.options.color}.svg`)}
              alt=''
              className='w-30px'
            />
          }
        </td>
      ) : null}
      <td>
        {IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
          currentUser
        ) ? (
          <Link to={'/client/info/' + client.token} className='text-primary' target='_blank'>
            {client.externalId}
          </Link>
        ) : (
          client.externalId
        )}
      </td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <>
          <td>
            <div>{client.merchant.title}</div>
          </td>
          <td className='min-w-350px text-break'>{client.comment}</td>
        </>
      )}

      <td>
        {client.lastActivityDate
          ? format(parseISO(client.lastActivityDate), 'dd.MM.yyyy HH:mm')
          : ''}
      </td>
      <td>{format(parseISO(client.createdAt), 'dd.MM.yyyy HH:mm')}</td>
      {props.chooseClient ? (
        <td className='float-right'>
          <button
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-4'
            onClick={() => {
              props.setClient(client)
            }}
          >
            <KTIcon className='fs-1' iconName='check-square' />
          </button>
        </td>
      ) : (
        ''
      )}
    </tr>
  )
}
