import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { Filters } from './../../components/Filters'
import { IFilter } from './../../components/Filters/Filters.types'
import { fetchRequisitesHistoryLogs } from './../../api/fetchLogs'
import { Table } from '../../../../../components/Table'
import { TableHead } from '../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../components/Table/TableBody'
import { IRequisiteHistoryElement } from './../../api/logs.types'
import { convertToUTCByMSK, convertUTCtoMSK } from '../../../../../utils'
import { getDefaultDateRange } from '../../../../../utils/getDefaultDateRange'
import { downloadFile } from '../../../../../utils/downloadFile'
import { REQUISITE_EVENT_NAME } from '../../../../../constants'

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Реквизиты',
    path: '/logs/requisites',
    isSeparator: false,
    isActive: true,
  },
]

export const Requisites = () => {
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<IFilter>({
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
  })
  const [requisitesHistoryLogs, setRequisitesHistoryLogs] = useState<IRequisiteHistoryElement[]>([])
  const [requisitesHistoryCountPages, setRequisitesHistoryCountPages] = useState(0)
  const [requisitesHistoryCurrentPage, setRequisitesHistoryCurrentPage] = useState(1)
  const [isLoadingRequisitesHistory, setIsLoadingRequisitesHistory] = useState(false)

  const requisitesHistory = async (reset?: boolean) => {
    let query: any = {}

    if (reset) {
      query = {
        page: 1,
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...filters,
        page: requisitesHistoryCurrentPage,
        dateStart: convertToUTCByMSK(filters.dateStart),
        dateEnd: convertToUTCByMSK(filters.dateEnd),
      }
    }

    setIsLoadingRequisitesHistory(true)

    try {
      const { data: requisitesHistoryData } = await fetchRequisitesHistoryLogs({
        ...filters,
        page: requisitesHistoryCurrentPage,
        dateStart: convertToUTCByMSK(filters.dateStart),
        dateEnd: convertToUTCByMSK(filters.dateEnd),
      })

      if (requisitesHistoryData.success) {
        setRequisitesHistoryLogs(requisitesHistoryData.requisiteHistory)
        setIsLoadingRequisitesHistory(false)
        setRequisitesHistoryCountPages(Math.ceil(requisitesHistoryData.totalRows / 100))
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  const fetchData = (reset?: boolean) => {
    requisitesHistory(reset)
  }

  const exportRequisiteHistory = async () => {
    AppSwal.fire({
      title: 'Экспорт в процессе...',
      showLoaderOnConfirm: true,
      didOpen: async () => {
        Swal.showLoading()
        try {
          const { data: blob } = await fetchRequisitesHistoryLogs({
            ...filters,
            page: requisitesHistoryCurrentPage,
            dateStart: convertToUTCByMSK(filters.dateStart),
            dateEnd: convertToUTCByMSK(filters.dateEnd),
            xlsx: true,
          })

          if (blob) {
            downloadFile(blob, 'requisite_events', 'xlsx')

            AppSwal.close()
          }
        } catch (err) {
          AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }

  const updateFilter = (obj: IFilter) => {
    setFilters(obj)
  }

  useEffect(() => {
    requisitesHistory()
  }, [requisitesHistoryCurrentPage])

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Логи действий с реквизитами</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            initialFilter={filters}
            updateFilter={(obj) => updateFilter(obj)}
            callback={(reset?: boolean) => fetchData(reset)}
            hasExport
            onExport={exportRequisiteHistory}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8 mb-7'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='card-rounded w-100'>
                <div className='d-flex flex-column'>
                  <Table>
                    <TableHead>
                      <tr>
                        <th>Дата/Время</th>
                        <th>Реквизит</th>
                        <th>Клиент/Сотрудник</th>
                        <th>Тикет токен</th>
                        <th>External user id</th>
                        <th>Действие</th>
                      </tr>
                    </TableHead>
                    <TableBody>
                      {isLoadingRequisitesHistory ? (
                        <tr>
                          <td colSpan={3}>Идет загрузка...</td>
                        </tr>
                      ) : requisitesHistoryLogs.length ? (
                        requisitesHistoryLogs.map(
                          (requisitesHistoryLog: IRequisiteHistoryElement, index) => (
                            <tr key={index}>
                              <td>{convertUTCtoMSK(requisitesHistoryLog.createdAt)}</td>
                              <td>{requisitesHistoryLog.requisite?.name}</td>
                              <td>{requisitesHistoryLog?.user?.uuid}</td>
                              <td>{requisitesHistoryLog?.paymentTicket?.token || '-'}</td>
                              <td>{requisitesHistoryLog?.externalUser?.externalUserId || '-'}</td>
                              <td>{REQUISITE_EVENT_NAME[requisitesHistoryLog.event]}</td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={3}>Данных нет.</td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>

                  <ReactPaginate
                    activeClassName={'active-pagination '}
                    breakClassName={'item-pagination break-me-pagination '}
                    containerClassName={'pagination-pagination'}
                    disabledClassName={'disabled-page-pagination'}
                    nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
                    pageClassName={'item-pagination pagination-page-pagination '}
                    previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
                    breakLabel='...'
                    nextLabel=''
                    onPageChange={(pageNumber) => {
                      setRequisitesHistoryCurrentPage(pageNumber.selected + 1)
                    }}
                    pageRangeDisplayed={5}
                    pageCount={requisitesHistoryCountPages}
                    previousLabel=''
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
