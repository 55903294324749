import ReactPaginate from 'react-paginate'

import { Table } from '../../../../../../../components/Table'
import { TableHead } from '../../../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../../../components/Table/TableBody'
import { TransactionTableRow } from '../TransactionTableRow'
import { IDataTableProps } from './DataTable.types'

export const DataTable = (props: IDataTableProps) => {
  const { countPages, currentPage, data } = props
  return (
    <>
      <Table>
        <TableHead>
          <tr>
            <th className='min-w-150px'>Дата / время</th>
            <th className='min-w-140px'>Сумма</th>
            <th className='min-w-140px'>Касса</th>
            <th className='min-w-100px'>Сотрудник</th>
            <th className='min-w-140px'>Статус</th>
            <th className='min-w-120px'>Тип операции</th>
          </tr>
        </TableHead>
        <TableBody>
          {data && data.length ? (
            data.map((t, index) => (
              <TransactionTableRow key={`${t.created_at}-${index}`} data={t} />
            ))
          ) : (
            <tr>
              <td colSpan={6}>Нет данных</td>
            </tr>
          )}
        </TableBody>
      </Table>

      <ReactPaginate
        activeClassName={'active-pagination '}
        breakClassName={'item-pagination break-me-pagination '}
        containerClassName={'pagination-pagination'}
        disabledClassName={'disabled-page-pagination'}
        nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
        pageClassName={'item-pagination pagination-page-pagination '}
        previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
        breakLabel='...'
        nextLabel=''
        onPageChange={(pageNumber) => {
          // reportFilterData.page = pageNumber.selected + 1
          // updateTicketList(reportFilterData, false)
          console.log('page change: ', pageNumber)
        }}
        pageRangeDisplayed={5}
        pageCount={countPages}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </>
  )
}
