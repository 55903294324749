import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getIncomingLogs } from '../../api/getIncomingLogs'
import { downloadFile } from '../../../../../utils/downloadFile'

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Push и SMS',
    path: '/logs/push-and-sms',
    isSeparator: false,
    isActive: true,
  },
]

export const PushAndSms = () => {
  const exportIncomingLogs = async () => {
    AppSwal.fire({
      title: 'Экспорт в процессе...',
      showLoaderOnConfirm: true,
      didOpen: async () => {
        Swal.showLoading()
        try {
          const { data: blob } = await getIncomingLogs()

          if (blob) {
            downloadFile(blob, 'push-and-sms', 'zip')

            AppSwal.close()
          }
        } catch (err) {
          AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    })
  }

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Push и SMS</span>
          </h3>
        </div>
      </div>

      <div className='card'>
        <div className='card-body border-0'>
          <button className='btn btn-success' onClick={exportIncomingLogs}>
            Скачать логи
          </button>
        </div>
      </div>
    </div>
  )
}
