import { SyntheticEvent, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { KTIcon } from '../../../../../_metronic/helpers'
import { useFormik } from 'formik'
import { DEFAULT_NEW_EMPLOYEE_OBJ, NEW_EMPLOYEE_SCHEMA, ROLES_FOR_NEW_EMPLOYEE } from './consts'
import { INewEmployee } from './newEmployee.types'
import { ROLE_NAME } from '../../../../../constants'
import { addNewEmployee } from '../../api'
import { getListCashBoxesForSelect } from '../../../../common/core/_requests'
import { SelectOption } from '../../../../common/filter/models'
import { ICashBox } from '../../../../../types'

const AppSwal = withReactContent(Swal)

export const NewEmployee = ({ callback }: { callback: () => void }) => {
  const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false)
  const [cashboxes, setCashboxes] = useState<Array<SelectOption>>([])
  const [loadingCashboxes, setLoadingCashboxes] = useState(false)
  const [loading, setLoading] = useState(false)

  const initialValues: INewEmployee = { ...DEFAULT_NEW_EMPLOYEE_OBJ }
  const formik = useFormik<INewEmployee>({
    initialValues,
    validationSchema: NEW_EMPLOYEE_SCHEMA,
    onSubmit: (values) => {
      addEmployee(values)
    },
    enableReinitialize: true,
  })

  const fetchCashboxes = async () => {
    setLoadingCashboxes(true)

    try {
      const response = await getListCashBoxesForSelect()

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data.cashBoxes) {
        const cashBoxes: Array<SelectOption> = []

        response.data.cashBoxes.forEach((value: ICashBox) => {
          const item = {
            value: value.id,
            label: value.title,
          }

          cashBoxes.push(item)
        })

        setCashboxes(cashBoxes)
        setLoadingCashboxes(false)
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  useEffect(() => {
    if (['ROLE_CASHIER', 'ROLE_SENIOR_CASHIER'].includes(formik.values.role)) {
      if (!cashboxes.length) {
        fetchCashboxes()
      }
    }
  }, [formik.values])

  const onClickHandler = () => {
    setShowAddEmployeeModal(true)
  }

  const onCancelHandler = (e: SyntheticEvent) => {
    formik.handleReset(e)
    setShowAddEmployeeModal(false)
  }

  const addEmployee = async ({ uuid, password, role, cashboxId }: any) => {
    setLoading(true)

    try {
      const response = await addNewEmployee(uuid, password, role, cashboxId)

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data) {
        formik.resetForm()
        setShowAddEmployeeModal(false)
        AppSwal.fire({
          title: `Новый сотрудник '${uuid}' успешно добавлен!`,
          timer: 1500,
          willClose: () => {
            callback()
          },
        })
      }

      setLoading(false)
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  return (
    <>
      <button className='btn btn-success' onClick={onClickHandler}>
        Добавить нового сотрудника
      </button>

      <Modal
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px'
        show={showAddEmployeeModal}
        backdrop={true}
      >
        <div className='modal-header'>
          <h2>Добавить нового сотрудника</h2>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            onClick={() => setShowAddEmployeeModal(false)}
          >
            <KTIcon className='fs-1' iconName='cross' />
          </div>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Логин</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Логин'
                    {...formik.getFieldProps('uuid')}
                  />
                  {formik.touched.uuid && formik.errors.uuid && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.uuid}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Пароль</label>

                <div className='col-lg-8 fv-row'>
                  <input
                    type='password'
                    autoComplete='off'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Пароль'
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Роль</label>

                <div className='col-lg-8 fv-row'>
                  <select
                    className='form-select form-select-solid form-select-lg fw-bold'
                    {...formik.getFieldProps('role')}
                  >
                    <option value={''}>{''}</option>
                    {ROLES_FOR_NEW_EMPLOYEE.map((value, index) => (
                      <option value={value} key={index}>
                        {ROLE_NAME[value]}
                      </option>
                    ))}
                  </select>
                  {formik.touched.role && formik.errors.role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.role}</div>
                    </div>
                  )}
                </div>
              </div>

              {['ROLE_CASHIER', 'ROLE_SENIOR_CASHIER'].includes(
                formik.getFieldProps('role').value
              ) && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Касса</label>

                  <div className='col-lg-8 fv-row'>
                    <select
                      className='form-select form-select-solid form-select-lg fw-bold'
                      {...formik.getFieldProps('cashboxId')}
                    >
                      <option value={''}>{loadingCashboxes ? 'Загрузка касс' : ''}</option>
                      {cashboxes.map((cashbox, index) => (
                        <option value={cashbox.value} key={index}>
                          {cashbox.label}
                        </option>
                      ))}
                    </select>
                    {formik.touched.cashboxId && formik.errors.cashboxId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.cashboxId}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn' onClick={onCancelHandler}>
                Отмена
              </button>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Добавить сотрудника'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Идёт добавление сотрудника...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}
