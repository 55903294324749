import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { PageTitle } from '../../../../../_metronic/layout/core'
import { CLIENTS_PAGE_BREADCRUMBS } from '../../ClientsPage.consts'

import { Filters } from './components/Filters'
import { IFilter } from './components/Filters/Filters.types'
import { Summary } from './components/Summary'
import { fetchTransactions } from './api'
import { DataTable } from './components/DataTable'
import { ISummaryData, ITransaction } from './api/transactions.types'
import { convertToUTCByMSK } from '../../../../../utils'

const breadCrumbs = [...CLIENTS_PAGE_BREADCRUMBS]

export const Transactions = () => {
  const { token } = useParams()
  const [countPages, setCountPages] = useState(0)
  const [currentPage] = useState(1)
  const [filter, setFilter] = useState<IFilter>({
    dateStart: new Date(Date.now() - 24 * 7 * 60 * 60 * 1000),
    dateEnd: new Date(),
    type: ['payment', 'payout'],
  })
  const [loading, setLoading] = useState(false)
  const [transactionsList, setTransactionsList] = useState<ITransaction[]>([])
  const [summary, setSummary] = useState<ISummaryData>({} as ISummaryData)

  useEffect(() => {
    breadCrumbs.forEach((value, index) => {
      if (value.title === 'Настройки') {
        breadCrumbs[index].isActive = true
      }
      breadCrumbs[index].path = breadCrumbs[index].path.replace('__token__', `${token}`)
    })

    fetchData()
  }, [])

  const fetchData = async () => {
    if (token) {
      setLoading(true)

      try {
        const { data: response } = await fetchTransactions(token, {
          ...filter,
          dateStart: convertToUTCByMSK(filter.dateStart),
          dateEnd: convertToUTCByMSK(filter.dateEnd),
        })

        if (response.success && response.data) {
          setTransactionsList(response.data.transactionList)
          setSummary(response.data.summary)
          setCountPages(Math.ceil(response.totalRows / 10))
          setLoading(false)
        }
      } catch (err) {
        console.log('error catched: ', err)
      }
    }
  }

  const updateFilter = (obj: IFilter) => {
    setFilter(obj)
  }

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Транзакции</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            initialFilter={filter}
            updateFilter={(obj) => updateFilter(obj)}
            callback={fetchData}
          />
        </div>
      </div>

      {loading ? (
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column text-center'>
            <span className='card-label fw-bold fs-3 mb-1'>Идёт загрузка данных...</span>
          </h3>
        </div>
      ) : (
        <>
          <div className='row g-5 g-xl-8 mb-7'>
            <Summary data={summary} />
          </div>

          <div className='card'>
            <div className='card-body'>
              <DataTable
                countPages={countPages || 1}
                currentPage={currentPage}
                data={transactionsList}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
