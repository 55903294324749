import axios from 'axios'
import { API_URL } from '../../../../constants'
import { IGeneralErrorObject } from '../../../../types'

export interface IPostPrioritiesReqQuery {
  priorities: any
}

export interface IPostPrioritiesRes {
  success: boolean
  totalRows: number
  errors: Partial<IGeneralErrorObject>
}

export const postPriorities = (query: IPostPrioritiesReqQuery) =>
  axios.post<IPostPrioritiesRes>(`${API_URL}/api/internal/cash-box/priorities/`, query)
