import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { ROLE } from '../auth'
import { PrivateRoute } from '../auth/PrivateRoute'
import { ClientsList } from './routes/List'
import { ClientInfo } from './routes/Info'
import { EditClient } from './routes/Edit'
import { Transactions } from './routes/Transactions'

const ClientsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='/list'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_OPERATOR,
                ROLE.ROLE_OPERATOR,
              ]}
            >
              <ClientsList />
            </PrivateRoute>
          }
        />
        <Route
          path='/info/:token'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_OPERATOR,
                ROLE.ROLE_OPERATOR,
              ]}
            >
              <ClientInfo />
            </PrivateRoute>
          }
        />
        <Route
          path='/edit/:token'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <EditClient />
            </PrivateRoute>
          }
        />
        <Route
          path='/transactions/:token'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_OPERATOR,
                ROLE.ROLE_OPERATOR,
              ]}
            >
              <Transactions />
            </PrivateRoute>
          }
        />
        <Route index element={<Navigate to='/client' />} />
      </Route>
    </Routes>
  )
}

export default ClientsPage
