import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
import { useFormik } from 'formik'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { useAuth } from '../../../auth'
import { ClientEditRequest, EClientFlags } from '../../core/_models'
import { editClientByToken, getClientByToken } from '../../core/_requests'
import { CLIENTS_PAGE_BREADCRUMBS } from '../../ClientsPage.consts'

setLocale(ru)

const breadCrumbs = [...CLIENTS_PAGE_BREADCRUMBS]
const clientSchema = Yup.object().shape({
  color: Yup.string().required('Обязательное поле'),
  comment: Yup.string(),
})

const defaultClientEdit: ClientEditRequest = {
  color: '',
  comment: '',
}

const errorStyle = { color: 'red' }

export const EditClient: React.FC = () => {
  const { token } = useParams()
  const { currentUser } = useAuth()
  const intl = useIntl()

  const [client, setClient] = useState(defaultClientEdit)
  const [loading, setLoading] = useState(false)
  const [errorAPI, setErrorAPI] = useState('')

  breadCrumbs.forEach((value, index) => {
    if (value.title === 'Настройки') {
      breadCrumbs[index].isActive = true
    }
    breadCrumbs[index].path = breadCrumbs[index].path.replace('__token__', token + '')
  })

  let initialValues: ClientEditRequest = Object.assign(client, {})

  useEffect(() => {
    getClientInfo()
  }, [])

  let formik = useFormik<ClientEditRequest>({
    initialValues,
    validationSchema: clientSchema,
    onSubmit: (values) => {
      setLoading(true)
      saveClient(values)
    },
    enableReinitialize: true,
  })

  const getClientInfo = async () => {
    let response = await getClientByToken(token + '', currentUser)

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.externalUser) {
      setClient({
        color: response.data.externalUser.options.color,
        comment: response.data.externalUser.comment ? response.data.externalUser.comment : '',
      })
    } else if (response.data.errors) {
      setErrorAPI(
        'Возникла ошибка: ' + (response.data.errors ? response.data.errors.general_error : '')
      )
    }
  }

  const saveClient = async (values: ClientEditRequest) => {
    let copyUpdateValues = Object.assign({}, values)
    let response = await editClientByToken(token + '', copyUpdateValues)

    setLoading(false)

    if (response.data.success && response.data.externalUser) {
      Swal.fire({
        title: 'Клиент успешно изменён',
        icon: 'success',
        timer: 1500,
      })

      setClient({
        color: response.data.externalUser.options.color,
        comment: response.data.externalUser.comment ? response.data.externalUser.comment : '',
      })
      setErrorAPI('')
    } else if (response.data.errors) {
      Object.entries(response.data.errors).forEach(([key, value]) => {
        if (key === 'general_error') {
          setErrorAPI('Возникла ошибка: ' + value)
        } else {
          formik.setFieldError(key, value)
        }
      })
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <PageTitle breadcrumbs={breadCrumbs}>Редактирование клиента</PageTitle>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Редактирование клиента</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Флаг</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('color')}
                >
                  {Object.values(EClientFlags).map((value, index) => (
                    <option value={value} key={index}>
                      {intl.formatMessage({ id: 'client.flag.' + value })}
                    </option>
                  ))}
                </select>
                {formik.touched.color && formik.errors.color && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.color}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Комментарий</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Комментарий'
                  {...formik.getFieldProps('comment')}
                />
                {formik.touched.comment && formik.errors.comment && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.comment}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {errorAPI ? (
            <div className='row mb-6 fw-bold fs-6 border-top p-9' style={errorStyle}>
              {errorAPI}{' '}
            </div>
          ) : (
            ''
          )}
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Сохранить'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Идёт сохранение...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
