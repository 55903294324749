import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { SortableList, SortableItem } from '@thaddeusjiang/react-sortable-list'
import '@thaddeusjiang/react-sortable-list/dist/index.css'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setLocale } from 'yup'
import { ru } from 'yup-locales'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getPaymentAdapterPriorities } from '../../api/getPaymentAdapterPriorities'
import { Filters } from './components/Filters'
import { postAdapterPriorities } from '../../api/postAdapterPriorities'
import { IPaymentAdapter } from '../../../../../types'
import { getPaymentAdapterList } from '../../api/getPaymentAdapterList'
import PaymentAdapterCard from './components/PaymentAdapterCard'
import { useWindowSize } from '../../../../../utils/hooks/useWindowSize'

setLocale(ru)

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Приоритет платежных адаптеров',
    path: '/settings/payment-adapter-priorities',
    isSeparator: false,
    isActive: true,
  },
]

const DragHandler = (props: any) => {
  return (
    <div
      title='drag handler'
      {...props}
      className='bg-secondary'
      style={{ padding: '15px', marginRight: '20px' }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className='w-6 h-6'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5'
        />
      </svg>
    </div>
  )
}

const PaymentAdapterPriorities = () => {
  const [prioritiesList, setPrioritiesList] = useState<any>([])
  const [adapters, setAdapters] = useState<IPaymentAdapter[]>()
  const [merchant, setMerchant] = useState<string>('')
  const [paymentMethod, setPaymentMethod] = useState<string>('')
  const [externalUserFlag, setExternalUserFlag] = useState<string>('')
  const [currency, setCurrency] = useState<string>('')
  const [direction, setDirection] = useState<string>('')
  const { width } = useWindowSize()

  const selectedFilters = useMemo(
    () => ({
      merchantToken: merchant,
      paymentMethodType: paymentMethod,
      externalUserFlag,
      currency,
      direction,
    }),
    [merchant, paymentMethod, currency, direction, externalUserFlag]
  )

  const [formattedAdapterPrioritiesList, setFormattedAdapterPrioritiesList] = useState([])

  const fetchAdapters = async () => {
    try {
      const { data } = await getPaymentAdapterList()

      if (data && data.success) {
        setAdapters(data.paymentAdapters)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  useEffect(() => {
    fetchAdapters()
  }, [])

  useEffect(() => {
    const formattedList: any = []

    if (prioritiesList.length) {
      prioritiesList.forEach((priority: any) => {
        const newAdapter = {
          ...priority.paymentAdapter,
          id: priority.paymentAdapter.id.toString(),
          name: priority.paymentAdapter.name,
          enabled: priority.enabled,
          minDeposit: priority.minDeposit,
        }
        formattedList.push(newAdapter)
      })
    }

    if (adapters?.length) {
      adapters.forEach((adapter) => {
        if (!formattedList.find((item: any) => item.id === adapter.id.toString())) {
          const newCashBox = {
            ...adapter,
            id: adapter.id.toString(),
            name: adapter.name,
            enabled: false,
            minDeposit: adapter.minDeposit,
          }
          formattedList.push(newCashBox)
        }
      })
    }

    setFormattedAdapterPrioritiesList(formattedList)
  }, [prioritiesList])

  const fetchAdapterPriorities = async () => {
    try {
      const { data } = await getPaymentAdapterPriorities(selectedFilters)

      if (data && data.success) {
        setPrioritiesList(data.paymentAdapterPriorities)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const preparedPostData = useMemo(
    () =>
      formattedAdapterPrioritiesList.map((fa: any, index) => ({
        paymentAdapterId: +fa?.id,
        enabled: fa.enabled,
        priority: index + 1,
        minDeposit: fa.minDeposit || 0,
      })),
    [formattedAdapterPrioritiesList]
  )

  const saveAdapterPriorities = async () => {
    try {
      const { data } = await postAdapterPriorities({
        priorities: preparedPostData,
        merchantToken: merchant,
        paymentMethodType: paymentMethod,
        externalUserFlag,
        currency,
        direction,
      })

      if (data && data.success && !data.errors) {
        AppSwal.fire({
          title: `Приоритет платежных адаптеров успешно сохранен!`,
          timer: 1500,
        })
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const onToggle = (e: ChangeEvent<HTMLInputElement>, item: any) => {
    const newFormattedPrioritiesList = [...formattedAdapterPrioritiesList]
    let toggledItem: any = newFormattedPrioritiesList.find((el: any) => el.id === item.id)

    if (toggledItem) {
      toggledItem.enabled = !toggledItem.enabled
    }

    setFormattedAdapterPrioritiesList(newFormattedPrioritiesList)
  }

  const arrowClickHandler = (item: any, direction: 'up' | 'down') => {
    setFormattedAdapterPrioritiesList((prev) => {
      const newData = [...prev]
      prev.forEach((el: any, i) => {
        if (el.id === item.id) {
          const part = newData.splice(i, 1)
          if (direction === 'up') {
            newData.splice(i > 0 ? i - 1 : i, 0, ...part)
          } else {
            newData.splice(i + 1, 0, ...part)
          }
        }
      })
      return newData
    })
  }

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Приоритет платежных адаптеров</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            selectedFilters={selectedFilters}
            setMerchant={(value: string) => setMerchant(value)}
            setPaymentMethod={(value: string) => setPaymentMethod(value)}
            setCurrency={(value: string) => setCurrency(value)}
            setDirection={(value: string) => setDirection(value)}
            setExternalUserFlag={(value: string) => setExternalUserFlag(value)}
            onSearch={fetchAdapterPriorities}
          />
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <div className='card-rounded'>
            <div
              style={{
                marginLeft: width <= 540 ? '155px' : '260px',
                marginBottom: '10px',
                width: '100px',
              }}
            >
              <span>Мин.сумма</span>
            </div>
            <div className='row' style={{display: 'flex', justifyContent: 'space-between'}}>
              {formattedAdapterPrioritiesList.length ? (
                <>
                  <div className='col-lg-5'>
                    <SortableList
                      disabled={width <= 1024 ? true : false}
                      items={formattedAdapterPrioritiesList}
                      //@ts-ignore
                      setItems={setFormattedAdapterPrioritiesList}
                    >
                      {({ items }) => (
                        <>
                          {items.map((item: any, index: any) => (
                            <SortableItem
                              key={item?.id}
                              id={item?.id}
                              DragHandler={width <= 1024 ? undefined : DragHandler}
                              className='d-flex align-items-center'
                            >
                              <div className='d-flex align-items-center'>
                                <div className={width <= 540 ? 'me-4' : 'me-2'}>#{index + 1}</div>
                                {width <= 1024 ? (
                                  <>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginRight: '6px',
                                      }}
                                    >
                                      <button onClick={() => arrowClickHandler(item, 'up')}>
                                        <i className='bi bi-caret-up'></i>
                                      </button>
                                      <button onClick={() => arrowClickHandler(item, 'down')}>
                                        <i className='bi bi-caret-down'></i>
                                      </button>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <div
                                  className={clsx('d-block p-4 my-4 me-4 text-center', {
                                    'text-white bg-primary': item.enabled,
                                    'text-black bg-secondary': !item.enabled,
                                  })}
                                  style={{ height: '50px', width: width < 540 ? '100px' : '150px' }}
                                >
                                  {item.name}
                                </div>
                                <PaymentAdapterCard
                                  item={item}
                                  onToggle={onToggle}
                                  setAdapter={setFormattedAdapterPrioritiesList}
                                />
                              </div>
                            </SortableItem>
                          ))}
                        </>
                      )}
                    </SortableList>
                  </div>
                  <div className='col-lg-3 d-flex'>
                    <button
                      className='btn btn-success my-auto me-5'
                      onClick={saveAdapterPriorities}
                    >
                      Сохранить приоритет
                    </button>
                  </div>
                </>
              ) : (
                <div className='col-lg-12'>
                  Измените фильтр и нажмите "Искать", для начала работы.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentAdapterPriorities
