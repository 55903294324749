export const SelectStyles = (theme = 'dark') => {
  return {
    control: (baseStyles: any, { data, isDisabled, isFocused, isSelected }: any) => ({
      ...baseStyles,
      ...(theme === 'dark' && {
        background: isFocused ? 'var(--bs-gray-200)' : 'var(--bs-gray-100)',
        border: 'var(--bs-gray-100)',
      }),
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        ...(theme === 'dark' && {
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? theme === 'dark'
              ? 'var(--bs-gray-200)'
              : 'white'
            : isFocused
            ? theme === 'dark'
              ? 'var(--bs-gray-200)'
              : 'white'
            : undefined,
          color: isDisabled
            ? '#ccc'
            : isSelected
            ? theme === 'dark'
              ? 'white'
              : undefined
            : 'white',
        }),

        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          ...(theme === 'dark' && {
            backgroundColor: !isDisabled
              ? isSelected
                ? 'var(--bs-gray-200)'
                : undefined
              : undefined,
          }),
        },
      }
    },
    singleValue: (styles: any) => ({
      ...styles,
      ...(theme === 'dark' && {
        color: 'white',
      }),
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      ...(theme === 'dark' && {
        backgroundColor: 'var(--bs-gray-200)',
      }),
    }),
    input: (styles: any) => ({ ...styles }),
    menu: (baseStyles: any, state: any) => ({
      ...baseStyles,
      ...(theme === 'dark' && {
        background: 'var(--bs-body-bg)',
      }),

      zIndex: 105,
    }),
  }
}
