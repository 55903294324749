import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from '../auth'

const DashboardPage: FC = () => {
  const { routeByRoleRedirect } = useAuth()
  const location = useLocation()

  return routeByRoleRedirect &&
    routeByRoleRedirect !== '/' &&
    routeByRoleRedirect !== '/dashboard' ? (
    <Navigate to={routeByRoleRedirect} state={{ from: location }} />
  ) : (
    <></>
  )
}

export default DashboardPage
