import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { format } from 'date-fns'
import fileDownload from 'js-file-download'
import MerchantFilter, {
  selectType as merchantSelectType,
} from '../../app/common/filter/MerchantFilter'
import { ClientFlagsOptions, SearchClientTimeFilter } from '../../app/common/core/_models'
import { SelectData } from '../../app/common/filter/models'
import DateTimeFilter from '../../app/common/filter/DateTimeFilter'
import SimpleSelectFilter from '../../app/common/filter/SimpleSelectFilter'
import { ROLE, useAuth } from '../../app/modules/auth'
import { ClientListFilterType } from '../../app/modules/clients/core/_rest_models'
import { IClientItem } from '../../app/modules/clients/core/_models'
import {
  getListClientsForSearch,
  postClientsExport,
} from '../../app/modules/clients/core/_requests'
import { ClientItem } from './ClientItem'
import { IClientsExportRequest, IClientsSearchPromise } from './ClientsSearch.types'
import { FLAGS_SELECT } from './ClientsSearch.constants'
import { Table } from '../Table'
import { TableHead } from '../Table/TableHead'
import { TableBody } from '../Table/TableBody'
import { IsGranted } from '../../app/modules/auth/PrivateRoute'

export const ClientsSearch = (props: { searchClientResult?: IClientsSearchPromise }) => {
  const { currentUser } = useAuth()
  const [resetFilter, setResetFilter] = useState<number | null>(null)
  const [clients, setClients] = useState<IClientItem[]>([])
  const [filterData, setFilterData] = useState<Partial<ClientListFilterType>>({
    perPage: 20,
  })
  const [countPages, setCountPages] = useState(0)
  const [fullCount, setFullCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchDebounce, setSearchDebounce] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const showFlagColumn = useMemo(
    () => currentUser?.roles.some((r) => [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER].includes(r)),
    [currentUser]
  )

  const setClient = (client: IClientItem) => {
    if (props.searchClientResult) {
      props.searchClientResult.promiseResolve(client)
    }
  }

  const onChangeSelect = (selectData: SelectData) => {
    let copyFilter = Object.assign({}, filterData)

    switch (selectData.type) {
      case merchantSelectType:
        if (!selectData.value.length) {
          delete copyFilter.merchantToken
        } else {
          copyFilter.merchantToken = selectData.value[0]
        }

        break
      case FLAGS_SELECT:
        if (!selectData.value.length || !selectData.value[0]) {
          delete copyFilter.color
        } else {
          copyFilter.color = selectData.value[0]
        }

        break
    }

    setFilterData(copyFilter)
  }

  const updateTimeFilter = (timeFilter: Date | null, type: SearchClientTimeFilter) => {
    const parsedTimeFilter = !timeFilter
      ? null
      : format(timeFilter, "yyyy-MM-dd'T'HH:mm") + '+00:00'
    const oldValue = filterData[type]
    let copyFilter = Object.assign({}, filterData)

    if (!parsedTimeFilter) {
      delete copyFilter[type]
    } else {
      copyFilter[type] = parsedTimeFilter
    }

    if (type === SearchClientTimeFilter.CREATED_FROM) {
      if (!timeFilter) {
        delete copyFilter[SearchClientTimeFilter.CREATED_TO]
      } else {
        copyFilter[SearchClientTimeFilter.CREATED_FROM] =
          format(timeFilter, "yyyy-MM-dd'T'") + '00:00:00+00:00'
        copyFilter[SearchClientTimeFilter.CREATED_TO] =
          format(timeFilter, "yyyy-MM-dd'T'") + '23:59:59+00:00'
      }
    }

    if (oldValue !== copyFilter[type]) {
      setFilterData(copyFilter)
    }
  }

  const searchClient = async () => {
    setLoading(true)

    try {
      const response = await getListClientsForSearch(filterData, currentUser)

      if (response.data.success && response.data.externalUsers) {
        setClients(response.data.externalUsers)
        setCountPages(Math.ceil((response.data.totalRows as number) / 20))
        setFullCount(response.data.totalRows as number)
        setLoading(false)
      }
    } catch (err) {
      console.log('error catch: ', err)
    }
  }

  useEffect(() => {
    searchClient()
  }, [filterData])

  useEffect(() => {
    return () => {
      clearTimeout(searchDebounce)
    }
  }, [])

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filter = { ...filterData }

    filter.search = e.target.value

    if (!filter.search || filter.search.length < 1) {
      delete filter.search
    }

    clearTimeout(searchDebounce)

    const newTimer = setTimeout(() => setFilterData(filter), 500)

    setSearchDebounce(newTimer)
  }

  const onExport = async () => {
    const {
      merchantToken,
      color,
      lastActivityDateStart,
      lastActivityDateEnd,
      page,
      perPage,
      ...rest
    } = filterData
    const query: IClientsExportRequest = {
      ...rest,
      ...(merchantToken && { merchantTokenList: [merchantToken] }),
      ...(lastActivityDateStart && { lastActiveDateStart: lastActivityDateStart }),
      ...(lastActivityDateEnd && { lastActiveDateEnd: lastActivityDateEnd }),
      ...(color && { flag: color }),
      xlsx: true,
    }

    let response = await postClientsExport(query)

    if (!response.data) {
      return false
    }

    if (response.data instanceof Blob) {
      fileDownload(response.data, `clients.xlsx`)

      return true
    }
  }

  const clientsRender = clients.map((client) => (
    <ClientItem
      client={client}
      key={client.token}
      setClient={setClient}
      chooseClient={!!props.searchClientResult}
      showFlagColumn={showFlagColumn}
    />
  ))

  return (
    <div>
      <div className='row g-8 mb-8'>
        <div className='col-xxl-12'>
          <div className='row g-8'>
            <div className='col-lg-4 col-xl-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Поиск</label>
              <div className='position-relative me-md-2'>
                <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6'>
                  <i className='fa fa-search'></i>
                </span>
                <input
                  type='text'
                  className='form-control form-control-solid ps-10'
                  onChange={onSearchChange}
                  placeholder='Поиск'
                  key={resetFilter}
                />
              </div>
            </div>

            {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
              <div className='col-lg-4 col-xl-3'>
                <label className='fs-6 form-label fw-bolder text-dark'>Флаг</label>
                <SimpleSelectFilter
                  options={ClientFlagsOptions}
                  changeSelect={(selectData: SelectData) => {
                    selectData.type = FLAGS_SELECT

                    onChangeSelect(selectData)
                  }}
                  initialFilter={ClientFlagsOptions[0]}
                  key={resetFilter}
                />
              </div>
            )}

            {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
              <div className='col-lg-4 col-xl-3'>
                <label className='fs-6 form-label fw-bolder text-dark'>Мерчант</label>
                <MerchantFilter
                  changeSelect={onChangeSelect}
                  key={resetFilter}
                  value={[filterData?.merchantToken || '']}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='row g-8'>
        <div className='col-xxl-12'>
          <div className='row g-8'>
            <div className='col-lg-4 col-xl-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Дата добавления</label>
              <DateTimeFilter
                updateTime={(dateTime: Date | null) => {
                  updateTimeFilter(dateTime, SearchClientTimeFilter.CREATED_FROM)
                }}
                placeholder={'Дата добавления'}
                key={resetFilter}
              />
            </div>

            <div className='col-lg-4 col-xl-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Активность с</label>
              <DateTimeFilter
                updateTime={(dateTime: Date | null) => {
                  dateTime?.setHours(0, 0, 0)
                  updateTimeFilter(dateTime, SearchClientTimeFilter.ACTIVE_FROM)
                }}
                placeholder={'Активность с'}
                key={resetFilter}
              />
            </div>

            <div className='col-lg-4 col-xl-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Активность по</label>
              <DateTimeFilter
                updateTime={(dateTime: Date | null) => {
                  dateTime?.setHours(23, 59, 59)
                  updateTimeFilter(dateTime, SearchClientTimeFilter.ACTIVE_TO)
                }}
                placeholder={'Активность по'}
                key={resetFilter}
              />
            </div>

            <div className='d-flex flex-column col-lg-3 text-end gap-2 mt-xl-0 w-md-100 w-xl-auto flex-xl-grow-1'>
              {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                <button
                  id='reset-search-client-form'
                  className='btn btn-primary'
                  onClick={onExport}
                >
                  Экспортировать
                </button>
              )}

              <button
                id='reset-search-client-form'
                className='btn btn-primary'
                onClick={() => {
                  setResetFilter(new Date().getTime())
                  setFilterData({})
                }}
              >
                Сбросить фильтр
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='card mt-10'>
        {/* begin::Body */}
        <div className='py-3'>
          <Table>
            <TableHead>
              <tr>
                {showFlagColumn && <th className='min-w-120px'>flag</th>}
                <th className='min-w-120px'>external user id</th>
                {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                  <>
                    <th className='min-w-150px'>мерчант</th>
                    <th className='min-w-350px'>комментарий</th>
                  </>
                )}
                <th className='min-w-150px'>последняя активность</th>
                <th className='min-w-150px'>дата создания</th>
                {!!props.searchClientResult && <th className='min-w-150px'></th>}
              </tr>
            </TableHead>
            <TableBody>
              {loading ? (
                <tr>
                  <td colSpan={7}>Загрузка...</td>
                </tr>
              ) : Object.keys(clients).length ? (
                clientsRender
              ) : (
                <tr>
                  <td colSpan={7}>Поиск не дал результатов</td>
                </tr>
              )}
            </TableBody>
          </Table>
        </div>
        {/* begin::Body */}
      </div>

      <ReactPaginate
        activeClassName={'active-pagination '}
        breakClassName={'item-pagination break-me-pagination '}
        containerClassName={'pagination-pagination'}
        disabledClassName={'disabled-page-pagination'}
        nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
        pageClassName={'item-pagination pagination-page-pagination '}
        previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
        breakLabel='...'
        nextLabel=''
        onPageChange={(pageNumber) => {
          setFilterData({
            ...filterData,
            page: pageNumber.selected + 1,
          })
        }}
        pageRangeDisplayed={5}
        pageCount={countPages || 1}
        previousLabel=''
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </div>
  )
}
