import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { SortableList, SortableItem } from '@thaddeusjiang/react-sortable-list'
import '@thaddeusjiang/react-sortable-list/dist/index.css'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { setLocale } from 'yup'
import { ru } from 'yup-locales'

import { handleAPIErrors } from '../../../../common/ErrorHandler'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { fetchCashboxesList, fetchPrioritiesList, postPriorities } from '../../api'
import { IPriority } from '../../api/fetchPrioritiesList'
import { Filters } from './components/Filters'
import { ICashBox } from '../../../../../types'
import { IFormattedPrioritiesListItem } from './CashboxesPrioritiesList.types'

setLocale(ru)

const AppSwal = withReactContent(Swal)

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Приоритет касс',
    path: '/settings/cashboxes-priorities',
    isSeparator: false,
    isActive: true,
  },
]

const CashboxesPriorities = () => {
  const [merchant, setMerchant] = useState<string>('')
  const [prioritiesList, setPrioritiesList] = useState<IPriority[]>([])
  const [cashboxesList, setCashboxesList] = useState<ICashBox[]>()

  const [direction, setDirection] = useState('')
  const [externalUserColor, setExternalUserColor] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')

  const selectedFilters = useMemo(
    () => ({
      merchantToken: merchant,
      direction,
      externalUserColor,
      paymentMethod,
    }),
    [merchant, direction, externalUserColor, paymentMethod]
  )

  const [formattedPrioritiesList, setFormattedPrioritiesList] = useState<
    IFormattedPrioritiesListItem[]
  >([])

  useEffect(() => {
    getCashboxesList()
  }, [])

  useEffect(() => {
    const formattedList: any = []

    if (prioritiesList.length) {
      prioritiesList.forEach((priority) => {
        const newCashBox = {
          ...priority.cashBox,
          id: priority.cashBox.id.toString(),
          name: priority.cashBox.title,
          enabled: priority.enabled,
        }
        formattedList.push(newCashBox)
      })
    }

    if (cashboxesList?.length) {
      cashboxesList.forEach((cashBox) => {
        if (!formattedList.find((item: any) => item.id === cashBox.id.toString())) {
          const newCashBox = {
            ...cashBox,
            id: cashBox.id.toString(),
            name: cashBox.title,
            enabled: false,
          }
          formattedList.push(newCashBox)
        }
      })
    }

    setFormattedPrioritiesList(formattedList)
  }, [prioritiesList])

  const getPrioritiesList = async () => {
    try {
      const { data, status } = await fetchPrioritiesList(selectedFilters)

      if (data && data.success) {
        setPrioritiesList(data.priorities)
      } else {
        handleAPIErrors(data.errors, status)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const getCashboxesList = async () => {
    try {
      const { data, status } = await fetchCashboxesList()

      if (data && data.success) {
        setCashboxesList(data.cashBoxes)
      } else {
        handleAPIErrors(data.errors, status)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const preparedPostData = useMemo(
    () =>
      formattedPrioritiesList.map((fp, index) => ({
        ...selectedFilters,
        cashBoxId: parseInt(fp.id),
        enabled: fp.enabled,
        priority: index + 1,
      })),
    [formattedPrioritiesList]
  )

  const savePriorities = async () => {
    try {
      const { data, status } = await postPriorities({
        priorities: preparedPostData,
      })

      if (data && data.success) {
        AppSwal.fire({
          title: `Приоритет касс успешно сохранен!`,
          timer: 1500,
        })
      } else {
        handleAPIErrors(data.errors, status)
      }
    } catch (err) {
      AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
    }
  }

  const onToggle = (e: ChangeEvent<HTMLInputElement>, item: any) => {
    const newFormattedPrioritiesList = [...formattedPrioritiesList]

    let toggledItem = newFormattedPrioritiesList.find((el) => el.id === item.id)

    if (toggledItem) {
      toggledItem.enabled = !toggledItem.enabled
    }

    setFormattedPrioritiesList(newFormattedPrioritiesList)
  }

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Приоритет касс</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            selectedFilters={selectedFilters}
            setDirection={(value: string) => setDirection(value)}
            setExternalUserColor={(value: string) => setExternalUserColor(value)}
            setPaymentMethod={(value: string) => setPaymentMethod(value)}
            setMerchant={(value: string) => setMerchant(value)}
            onSearch={getPrioritiesList}
          />
        </div>
      </div>

      <div className='card'>
        <div className='card-body'>
          <div className='card-rounded w-100'>
            <div className='row'>
              {formattedPrioritiesList.length ? (
                <>
                  <div className='col-lg-3'>
                    <SortableList
                      items={formattedPrioritiesList}
                      //@ts-ignore
                      setItems={setFormattedPrioritiesList}
                    >
                      {({ items }) => (
                        <>
                          {items.map((item: any, index: any) => (
                            <SortableItem key={item.id} id={item.id}>
                              <div className='d-flex items-center'>
                                <div className='me-4'>#{index + 1}</div>
                                <div
                                  className={clsx('d-block p-4 my-4 me-4 w-100 text-center', {
                                    'text-white bg-primary': item.enabled,
                                    'text-black bg-secondary': !item.enabled,
                                  })}
                                  style={{ height: '50px' }}
                                >
                                  {item.name}
                                </div>
                              </div>
                            </SortableItem>
                          ))}
                        </>
                      )}
                    </SortableList>
                  </div>
                  <div className='col-lg-4'>
                    {formattedPrioritiesList.map((fi) => (
                      <div className='flex' key={fi.id}>
                        <div className='d-flex items-center my-4' style={{ height: '50px' }}>
                          <div className='d-flex form-check form-check-solid form-switch fv-row'>
                            <input
                              style={{ cursor: 'pointer' }}
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              checked={fi.enabled}
                              onChange={(e) => onToggle(e, fi)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='col-lg-3 d-flex'>
                    <button className='btn btn-success my-auto me-5' onClick={savePriorities}>
                      Сохранить установленный приоритет касс
                    </button>
                  </div>
                </>
              ) : (
                <div className='col-lg-12'>
                  Измените фильтр и нажмите "Искать", для начала работы.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashboxesPriorities
