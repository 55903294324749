import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { EPayoutTicketStatus } from '../../../../types/enums'
import { Table } from '../../../../components/Table'
import { TableHead } from '../../../../components/Table/TableHead'
import { TableBody } from '../../../../components/Table/TableBody'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'
import { getTicketsList } from '../api/getTicketsList'
import { PayoutTicketListObject, PreparePayoutTicketsToObject } from '../core/_models'

import TicketItem from './TicketItem'

import '../../../assets/css/pagination.css'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payout-tickets/active',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payout-tickets/finished',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Отчёт',
    path: '/payout-tickets/report',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const defaultTicketList: PayoutTicketListObject = {}
const perPage = 20

const ActiveTickets = () => {
  const { currentUser } = useAuth()
  const [tickets, setTickets] = useState(defaultTicketList)
  const [countPages, setCountPages] = useState(0)
  const [fullCount, setFullCount] = useState(0)

  const getTicketList = async (page: number) => {
    let response = await getTicketsList({
      perPage: perPage,
      status: [...Object.values(EPayoutTicketStatus)],
      page: page,
    })

    if (response.data.success && response.data.tickets) {
      setTickets(PreparePayoutTicketsToObject(response.data.tickets))
      setCountPages(Math.ceil(response.data.totalRows / perPage))
      setFullCount(response.data.totalRows)
    }
  }

  useEffect(() => {
    getTicketList(1)
  }, [])

  const ticketsRender = Object.keys(tickets).map((key) => (
    <TicketItem ticket={tickets[key]} typePage={'Active'} key={key} />
  ))

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Активные</PageTitle>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Активные тикеты</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Количество: {fullCount}</span>
          </h3>
        </div>
      </div>

      <div className='card'>
        <div className='card-body py-3'>
          <Table>
            <TableHead>
              <tr>
                <th className='min-w-120px'>Id</th>
                <th className='min-w-150px'>Дата создания</th>
                <th className='min-w-150px'>Тип</th>
                <th className='min-w-120px'>Сумма</th>
                {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                  <th className='min-w-120px'>User id</th>
                )}
                <th className='min-w-120px'>External user id</th>
                <th className='min-w-150px'>Merchant id</th>
                <th className='min-w-150px'>Статус</th>
              </tr>
            </TableHead>
            <TableBody>{ticketsRender}</TableBody>
          </Table>
        </div>

        <ReactPaginate
          activeClassName={'active-pagination '}
          breakClassName={'item-pagination break-me-pagination '}
          containerClassName={'pagination-pagination'}
          disabledClassName={'disabled-page-pagination'}
          nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
          pageClassName={'item-pagination pagination-page-pagination '}
          previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
          breakLabel='...'
          nextLabel=''
          onPageChange={(pageNumber) => getTicketList(pageNumber.selected + 1)}
          pageRangeDisplayed={5}
          pageCount={countPages}
          previousLabel=''
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  )
}

export default ActiveTickets
