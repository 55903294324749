import { useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { KTIcon } from '../../../../../../../_metronic/helpers'
import { formatSum } from '../../../../../../../utils'
import { toggleActiveRequisite, releaseReservationRequisite } from '../../../../core/_requests'
import { postEnableReservation } from '../../../../api/postEnableReservation'
import { IRequisite } from '../../../../../../../types'

const AppSwal = withReactContent(Swal)

const RequisiteItem = (props: { requisite: IRequisite; requisiteItemCallback: () => void }) => {
  let activeBadge
  let reserveBadge

  const [requisite, setRequisite] = useState(props.requisite)
  const [loading, setLoading] = useState<{
    type: 'enabling' | 'disableReservation'
    isLoading: boolean
  }>({
    type: 'enabling',
    isLoading: false,
  })

  const toggleRequisite = async () => {
    setLoading({
      type: 'enabling',
      isLoading: true,
    })

    let response = await toggleActiveRequisite(requisite.id.toString())

    setLoading({
      type: 'enabling',
      isLoading: false,
    })

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.requisite) {
      props.requisiteItemCallback()
    }
  }

  const releaseRequisite = async () => {
    if (!requisite.isReserved || (await askStaffAboutAction()).isConfirmed) {
      let response = await releaseReservationRequisite(requisite.id.toString())

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data.requisite) {
        setRequisite(response.data.requisite)
      }
    }
  }

  const enableReservation = async () => {
    const response = await postEnableReservation(`${requisite.id} `)

    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.requisite) {
      const { currentPaymentLimitByAmount, currentPaymentLimitByTicketCount, ...rest } =
        response.data.requisite

      props.requisiteItemCallback()
    }
  }

  const askStaffAboutAction = () => {
    return AppSwal.fire({
      title: (
        <p>При снятии с резервирования, все связанные активные тикеты отменяются. Продолжить?</p>
      ),
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да',
      showCancelButton: true,
    })
  }

  if (requisite.enabled) {
    activeBadge = <span className='badge badge-light-success'>Включён</span>
  } else {
    activeBadge = <span className='badge badge-light-danger'>Отключён</span>
  }

  if (requisite.isReserved) {
    reserveBadge = (
      <span
        onClick={releaseRequisite}
        className='badge badge-light-info cursor-pointer'
        title='Снять с резерва'
      >
        Зарезервирован
      </span>
    )
  } else {
    reserveBadge = <span className='badge badge-light-success'>Свободен</span>
  }

  return (
    <tr>
      <td className='fw-bold'>{requisite.id}</td>
      <td className='text-break'>{requisite.name}</td>
      <td>
        <span className='badge'>{requisite.requisiteType}</span>
        <span className='separator' />
        <span className='badge'>{requisite.paymentMethods.map((pm) => pm.type).join(' ,')}</span>
      </td>
      <td>{requisite.cashBox.title}</td>
      <td>
        <div>Номер карты: {requisite.cardNumber}</div>
        <div>Номер телефона: {requisite.phone || '-'}</div>
        <div>Держатель карты: {requisite.cardholderName}</div>
      </td>
      <td>{requisite.accountNumber || '-'}</td>
      <td>{formatSum(requisite.balance)}</td>
      <td align='center'>
        <span
          className={`badge ${
            requisite.currentPaymentLimitByAmount === requisite.paymentLimitByAmount
              ? 'badge-light-danger'
              : ''
          }`}
        >
          {`${new Intl.NumberFormat()
            .format(requisite.currentPaymentLimitByAmount)
            .replaceAll(',', ' ')} / ${new Intl.NumberFormat()
            .format(requisite.paymentLimitByAmount)
            .replaceAll(',', ' ')}`}
        </span>
      </td>
      <td align='center'>
        <span
          className={`badge ${
            requisite.currentPaymentLimitByTicketCount === requisite.paymentLimitByTicketCount
              ? 'badge-light-danger'
              : ''
          }`}
        >
          {`${new Intl.NumberFormat()
            .format(requisite.currentPaymentLimitByTicketCount)
            .replaceAll(',', ' ')} / ${new Intl.NumberFormat()
            .format(requisite.paymentLimitByTicketCount)
            .replaceAll(',', ' ')}`}
        </span>
      </td>
      <td align='center'>
        <div className='d-flex flex-column'>
          <div className='pb-1'>
            <button
              onClick={toggleRequisite}
              title={requisite.enabled ? 'Отключить' : 'Включить'}
              className='btn p-0'
            >
              {loading.type === 'enabling' && loading.isLoading ? (
                <span className='badge badge-light-warning'>Идет запрос...</span>
              ) : (
                activeBadge
              )}
            </button>
          </div>
          <div className='pb-1'>
            <button
              onClick={enableReservation}
              title={requisite.enableReservation ? 'Доступно' : 'Недоступно'}
              className='btn p-0'
            >
              {requisite.enableReservation ? (
                <span className='badge'>Доступно</span>
              ) : (
                <span className='badge'>Недоступно</span>
              )}
            </button>
          </div>
          <div>{reserveBadge}</div>
        </div>
      </td>
      <td className='text-break'>{requisite.comment}</td>
      <td className='text-end'>
        <a
          href={'/settings/requisite-edit/' + requisite.id}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </a>
      </td>
    </tr>
  )
}

export default RequisiteItem
