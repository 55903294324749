import axios from 'axios'
import { FETCH_TRANSACTIONS_URL } from './consts'
import { IFetchTransactionsParams, IFetchTransactionsResponse } from './transactions.types'

export const fetchTransactions = (token: string, params: IFetchTransactionsParams) => {
  return axios.get<IFetchTransactionsResponse>(`${FETCH_TRANSACTIONS_URL}/${token}`, {
    params: {
      createdDateStart: params.dateStart,
      createdDateEnd: params.dateEnd,
      type: params.type,
    },
  })
}
