import axios from 'axios'
import { API_URL } from '../../../../constants'
import { ICashBox, IGeneralErrorObject } from '../../../../types'

export interface IFetchPrioritiesListReqQuery {
  direction: string
  externalUserColor: string
  paymentMethod: string
}

export interface IPriority {
  cashBox: ICashBox
  direction: string
  externalUserColor: string
  paymentMethod: string
  priority: number
  enabled: boolean
}

export interface IFetchPrioritiesListRes {
  priorities: IPriority[]
  success: boolean
  totalRows: number
  errors: Partial<IGeneralErrorObject>
}

export const fetchPrioritiesList = (query: IFetchPrioritiesListReqQuery) => {
  return axios.get<IFetchPrioritiesListRes>(`${API_URL}/api/internal/cash-box/priorities/list`, {
    params: {
      ...query,
    },
  })
}
