import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'

import { SelectData, SelectOptionString } from './models'
import { ETicketStatus } from '../../../types/enums'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'payment_ticket_status'

const PaymentTicketStatusesFilter = ({
  changeSelect,
  value,
  isMultiple,
}: {
  changeSelect: (selectData: SelectData) => void
  value: string[]
  isMultiple?: boolean
}) => {
  const { mode: theme } = useThemeMode()
  const intl = useIntl()
  const [options, setOptions] = useState<Array<SelectOptionString>>([])
  const [loading, setLoading] = useState(true)

  const updateListStatuses = () => {
    let statuses: Array<SelectOptionString> = []

    Object.keys(ETicketStatus).forEach((value) => {
      const ticketStatus = value as ETicketStatus

      if (ETicketStatus.IN_PROGRESS === ticketStatus || ETicketStatus.CREATED === ticketStatus) {
        return true
      }

      const item = {
        value: ticketStatus,
        label: intl.formatMessage({ id: `ticket.status.${ticketStatus}` }),
        isFixed: false,
      }

      statuses.push(item)
    })

    setOptions(statuses)
    setLoading(false)
  }

  useEffect(() => {
    updateListStatuses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? []
      : !isMultiple
      ? [selectedData.value]
      : selectedData.map((item: SelectOptionString) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return loading ? (
    <></>
  ) : (
    <Select
      value={options.filter((o: SelectOptionString) => value.find((v) => o.value === v))}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите статус'}
      styles={SelectStyles(theme)}
    />
  )
}

export default PaymentTicketStatusesFilter
