import { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { getListStaff } from '../../core/_requests'
import { StaffInList } from '../../core/_models'
import { StaffItem } from '../../components/StaffItem'
import { ROLE, useAuth } from '../../../auth'
import { IsGranted } from '../../../auth/PrivateRoute'
import { Table } from '../../../../../components/Table'
import { TableHead } from '../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../components/Table/TableBody'
import { NewEmployee } from '../../components/NewEmployee'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/staff/active',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER],
  },
  {
    title: 'Заблокированные',
    path: '/staff/blocked',
    isSeparator: false,
    isActive: true,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER],
  },
]

const defaultStaffList: Array<StaffInList> = []

export const BlockedStaff = () => {
  const { currentUser } = useAuth()
  const [staffList, setStaffList] = useState(defaultStaffList)
  const getStaffList = async () => {
    let res = await getListStaff(false)

    setStaffList(res.data.employees)
  }

  useEffect(() => {
    getStaffList()
  }, [])

  return (
    <>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <div className='row'>
          <div className='col text-end pb-4'>
            <NewEmployee callback={() => window.location.replace('/staff/active')} />
          </div>
        </div>
      )}

      <div className='card'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Заблокированные</PageTitle>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Заблокированные сотрудники</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Количество: {staffList.length}</span>
          </h3>
        </div>

        <div className='card-body py-3'>
          <Table>
            <TableHead>
              <tr>
                <th className='min-w-150px'>ID</th>
                <th className='min-w-140px'>Username</th>
                <th className='min-w-120px'>Роль</th>
              </tr>
            </TableHead>
            <TableBody>
              {staffList.map((staff, index) => (
                <StaffItem staff={staff} active={false} key={index} />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  )
}
