import { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import { Filters } from './../../components/Filters'
import { IFilter } from './../../components/Filters/Filters.types'
import { fetchLoginEventsLogs, fetchUserEventsLogs } from './../../api/fetchLogs'
import { Table } from '../../../../../components/Table'
import { TableHead } from '../../../../../components/Table/TableHead'
import { TableBody } from '../../../../../components/Table/TableBody'
import { ILoginEvent, IUserHistoryItem } from './../../api/logs.types'
import { convertToUTCByMSK, convertUTCtoMSK } from '../../../../../utils'
import { getDefaultDateRange } from '../../../../../utils/getDefaultDateRange'

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Логи',
    path: '/logs/employees',
    isSeparator: false,
    isActive: true,
  },
]

export const Employees = () => {
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<IFilter>({
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
  })
  const [loginEventsLogs, setLoginEventsLogs] = useState<ILoginEvent[]>([])
  const [loginLogsCountPages, setLoginLogsCountPages] = useState(0)
  const [loginLogsCurrentPage, setLoginLogsCurrentPage] = useState(1)
  const [isLoadingLoginLogs, setIsLoadingLoginLogs] = useState(false)
  const [, setUserEventsLogs] = useState<IUserHistoryItem[]>([])
  const [, setUserLogsCountPages] = useState(0)
  const [userLogsCurrentPage] = useState(1)
  const [, setIsLoadingUserLogs] = useState(false)

  const loginLogs = async (reset?: boolean) => {
    let query: any = {}

    if (reset) {
      query = {
        page: 1,
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...filters,
        page: loginLogsCurrentPage,
        dateStart: convertToUTCByMSK(filters.dateStart),
        dateEnd: convertToUTCByMSK(filters.dateEnd),
      }
    }

    setIsLoadingLoginLogs(true)

    try {
      const { data: loginEventsLogs } = await fetchLoginEventsLogs(query)

      if (loginEventsLogs.success) {
        setLoginEventsLogs(loginEventsLogs.loginEvents)
        setIsLoadingLoginLogs(false)
        setLoginLogsCountPages(Math.ceil(loginEventsLogs.totalRows / 20))
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  const userLogs = async (reset?: boolean) => {
    let query: any = {}

    if (reset) {
      query = {
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...filters,
        dateStart: convertToUTCByMSK(filters.dateStart),
        dateEnd: convertToUTCByMSK(filters.dateEnd),
      }
    }

    setIsLoadingUserLogs(true)

    try {
      const { data: userEventsLogs } = await fetchUserEventsLogs(query)

      if (userEventsLogs.success) {
        setUserEventsLogs(userEventsLogs.userHistory)
        setIsLoadingUserLogs(false)
        setUserLogsCountPages(Math.ceil(userEventsLogs.totalRows / 20))
      }
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  const fetchData = (reset?: boolean) => {
    loginLogs(reset)
    userLogs(reset)
  }

  const updateFilter = (obj: IFilter) => {
    setFilters(obj)
  }

  useEffect(() => {
    loginLogs()
  }, [loginLogsCurrentPage])

  useEffect(() => {
    userLogs()
  }, [userLogsCurrentPage])

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs} />
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Логи сотрудников</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <Filters
            initialFilter={filters}
            updateFilter={(obj) => updateFilter(obj)}
            callback={(reset?: boolean) => fetchData(reset)}
          />
        </div>
      </div>

      <div className='row g-5 g-xl-8 mb-7'>
        <div className='col'>
          <div className='card'>
            <div className='card-body'>
              <div className='card-rounded w-100'>
                <div className='d-flex flex-column'>
                  <Table>
                    <TableHead>
                      <tr>
                        <th>Дата</th>
                        <th>IP</th>
                        <th>Сотрудник ID</th>
                        <th>Действие</th>
                      </tr>
                    </TableHead>
                    <TableBody>
                      {isLoadingLoginLogs ? (
                        <tr>
                          <td colSpan={3}>Идет загрузка...</td>
                        </tr>
                      ) : loginEventsLogs.length ? (
                        loginEventsLogs.map((loginEventLog: ILoginEvent, index) => (
                          <tr key={index}>
                            <td>
                              {loginEventLog.createdAt && convertUTCtoMSK(loginEventLog.createdAt)}
                            </td>
                            <td>{loginEventLog.ip}</td>
                            <td>{loginEventLog.user?.uuid}</td>
                            <td>Авторизация</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>Данных нет.</td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>

                  <ReactPaginate
                    activeClassName={'active-pagination '}
                    breakClassName={'item-pagination break-me-pagination '}
                    containerClassName={'pagination-pagination'}
                    disabledClassName={'disabled-page-pagination'}
                    nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
                    pageClassName={'item-pagination pagination-page-pagination '}
                    previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
                    breakLabel='...'
                    nextLabel=''
                    onPageChange={(pageNumber) => {
                      setLoginLogsCurrentPage(pageNumber.selected + 1)
                    }}
                    pageRangeDisplayed={5}
                    pageCount={loginLogsCountPages}
                    previousLabel=''
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
