import { SelectOptionString } from '../filter/models'
import { ICashBox } from '../../../types'

export enum SearchClientTimeFilter {
  CREATED_FROM = 'createdDateStart',
  CREATED_TO = 'createdDateEnd',
  ACTIVE_FROM = 'lastActivityDateStart',
  ACTIVE_TO = 'lastActivityDateEnd',
}

export interface MerchantInCashBox {
  title: string
  token: string
}

export interface CashierInCashBox {
  id: number
  name: string
  enabled: boolean
}

export interface CashBoxInMerchant {
  id: number
  title: string
  enabled: boolean
  createdAt: string
}

export interface OperatorInMerchant {
  uuid: string
  enabled: boolean
  createdAt: string
}

export interface PlatformInMerchant {
  uuid: string
  enabled: boolean
  createdAt: string
}

export interface Merchant {
  title: string
  token: string
  enabled: boolean
  createdAt: string
  cashBoxes?: Array<ICashBox>
  operators?: Array<OperatorInMerchant>
  platform?: PlatformInMerchant
}

export const SEARCH_CLIENT_BY: Array<SelectOptionString> = [
  {
    value: 'external_user_id',
    label: 'external_user_id',
  },
]

export const ClientFlagsOptions: Array<SelectOptionString> = [
  {
    value: '',
    label: 'Любой',
  },
  {
    value: 'white',
    label: 'Белый',
  },
  {
    value: 'red',
    label: 'Красный',
  },
  {
    value: 'yellow',
    label: 'Жёлтый',
  },
  {
    value: 'green',
    label: 'Зелёный',
  },
  {
    value: 'blue',
    label: 'Синий',
  },
]
