import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { StaffInList } from '../../core/_models'
import { ROLE_NAME } from '../../../../../constants'
import { KTIcon } from '../../../../../_metronic/helpers'
import { postBlockEmployee } from '../../api/postBlockEmployee'
import { ROLE, useAuth } from '../../../auth'
import { IsGranted } from '../../../auth/PrivateRoute'

const AppSwal = withReactContent(Swal)

export const StaffItem = ({
  staff,
  active,
  callback,
  showActions,
}: {
  staff: StaffInList
  active: boolean
  callback?: () => void
  showActions?: boolean
}) => {
  const { currentUser } = useAuth()

  if (staff.roles) {
    const removeRoleIndex = staff.roles.indexOf('ROLE_USER')

    if (removeRoleIndex !== -1) {
      staff.roles.splice(removeRoleIndex, 1)
    }
  }

  const onClickBlockAction = () => {
    AppSwal.fire({
      title: `Вы точно хотите заблокировать сотрудника "${staff.uuid}"?`,
      showCancelButton: true,
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, заблокировать',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await postBlockEmployee(staff.id)

          if (response.status === 400) {
            Swal.fire('', `Невозможно заблокировать сотрудника "${staff.uuid}"`, 'warning')
          }

          if (response.data && response.data.success) {
          } else {
            throw new Error(response.data.errors.general_error)
          }
        } catch (err) {
          AppSwal.showValidationMessage(`Неверный запрос: ${err}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        AppSwal.fire({
          title: `Сотрудник "${staff.uuid}" успешно заблокирован!`,
          timer: 1500,
        })

        callback && callback()
      }
    })
  }

  return (
    <tr className={active ? '' : 'table-danger'}>
      <td className='fw-bold'>{staff.id}</td>
      <td>{staff.uuid}</td>
      <td>
        {staff.roles &&
          staff.roles
            .reduce((arr: string[], r: string) => {
              if (Object.keys(ROLE_NAME).includes(r)) {
                arr.push(ROLE_NAME[r])
              }

              return arr
            }, [])
            .sort()
            .join(', ')}
      </td>
      {showActions && IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <td>
          <div className='cursor-pointer' onClick={onClickBlockAction} title='Заблокировать'>
            <KTIcon className='fs-1' iconName='cross' />
          </div>
        </td>
      )}
    </tr>
  )
}
