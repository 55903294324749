import axios from 'axios'
import {
  RequisiteEdit,
  RequisiteEditResponse,
  RequisiteListResponse,
  RequisiteToggleResponse,
  RequisiteTotalSumResponse,
} from './_models'
import { env } from '../../../../env'

const API_URL = env.REACT_APP_API_URL

export const GET_LIST_REQUISITES = `${API_URL}/api/internal/requisite/list`
export const TOGGLE_ACTIVE_REQUISITE = `${API_URL}/api/internal/requisite/toggle-active/__id__`
export const RELEASE_RESERVATION_REQUISITE = `${API_URL}/api/internal/requisite/release-reservation/__id__`
export const ADD_REQUISITE = `${API_URL}/api/internal/requisite/new`
export const EDIT_REQUISITE = `${API_URL}/api/internal/requisite/edit/__id__`
export const GET_REQUISITE = `${API_URL}/api/internal/requisite/__id__`
export const GET_REQUISITES_TOTAL_SUM = `${API_URL}/api/internal/requisite/total-balance`

export function getListRequisites(params?: {
  page?: number
  enabled?: boolean
  disableReservation?: boolean
  cashBoxId?: number[]
  accountNumber?: string
}) {
  return axios.get<Partial<RequisiteListResponse>>(GET_LIST_REQUISITES, {
    params: {
      perPage: 99,
      page: params?.page ? params.page : 1,
      ...(params?.enabled && { enabled: params.enabled }),
      ...(params?.disableReservation !== undefined && {
        disableReservation: params.disableReservation,
      }),
      ...(params?.cashBoxId?.length && { cashBoxId: params.cashBoxId[0] }),
      ...(params?.accountNumber && { accountNumber: params.accountNumber }),
    },
  })
}

export function toggleActiveRequisite(id: string) {
  return axios.post<Partial<RequisiteToggleResponse>>(
    TOGGLE_ACTIVE_REQUISITE.replace('__id__', id),
    {}
  )
}

export function releaseReservationRequisite(id: string) {
  return axios.post<Partial<RequisiteToggleResponse>>(
    RELEASE_RESERVATION_REQUISITE.replace('__id__', id),
    {}
  )
}

export function editRequisite(requisite: Partial<RequisiteEdit>, idEdit: string) {
  let url = idEdit ? EDIT_REQUISITE.replace('__id__', idEdit) : ADD_REQUISITE

  return axios.post<Partial<RequisiteEditResponse>>(url, requisite)
}

export function getRequisiteAPI(id: string) {
  return axios.get<Partial<RequisiteEditResponse>>(GET_REQUISITE.replace('__id__', id))
}

export function getRequisitesTotalSum() {
  return axios.get<Partial<RequisiteTotalSumResponse>>(GET_REQUISITES_TOTAL_SUM)
}
