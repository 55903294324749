import { useState } from 'react'
import Select from 'react-select'

import { SelectData, SelectOptionString } from './models'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'simple_select'

const SimpleSelectFilter = (props: {
  options: Array<SelectOptionString>
  changeSelect: (selectData: SelectData) => void
  initialFilter?: SelectOptionString
  isMultiple?: boolean
}) => {
  const { mode: theme } = useThemeMode()
  const [keyUpdate, setKeyUpdate] = useState('1')

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? []
      : !props.isMultiple
      ? [selectedData.value]
      : selectedData.map((item: SelectOptionString) => item.value)

    props.changeSelect({
      value: values,
      type: selectType,
    })
  }

  return (
    <Select
      key={`my_unique_select_key__${keyUpdate}`}
      defaultValue={props.initialFilter}
      onChange={handleChange}
      isClearable={props.options.some((v) => !v.isFixed)}
      isMulti={props.isMultiple}
      options={props.options}
      placeholder={'Выберите значение'}
      styles={SelectStyles(theme)}
    />
  )
}

export default SimpleSelectFilter
