export interface AuthModel {
  auth_token: string
}

export interface UserModel {
  id: number
  uuid: string
  roles: Array<ROLE>
  merchants?: Array<number>
  cashBoxes?: Array<number>
  cashBox: number
}

export interface UserModelResponse {
  success: boolean
  userData: UserModel
}

export enum ROLE {
  ROLE_SMS_PROVIDER = 'ROLE_SMS_PROVIDER',
  ROLE_PLATFORM_NOTIFICATOR = 'ROLE_PLATFORM_NOTIFICATOR',
  ROLE_PLATFORM = 'ROLE_PLATFORM',
  ROLE_EMPLOYEE = 'ROLE_EMPLOYEE',
  ROLE_CASHIER = 'ROLE_CASHIER',
  ROLE_OPERATOR = 'ROLE_OPERATOR',
  ROLE_SENIOR_CASHIER = 'ROLE_SENIOR_CASHIER',
  ROLE_SENIOR_OPERATOR = 'ROLE_SENIOR_OPERATOR',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}
