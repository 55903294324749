import { useMemo } from 'react'
import Select from 'react-select'
import { IFiltersProps } from './Filters.types'
import { CLIENT_COLOR_OPTIONS } from './consts'
import { SelectStyles } from '../../../../../../../components/Select'
import { PAYMENT_DIRRECTION_OPTIONS, PAYMENT_METHODS_OPTIONS } from '../../../../../../../constants'
import { useThemeMode } from '../../../../../../../_metronic/partials'
import MerchantFilter from '../../../../../../common/filter/MerchantFilter'

export const Filters = (props: IFiltersProps) => {
  const { mode: theme } = useThemeMode()
  const {
    selectedFilters,
    setMerchant,
    setDirection,
    setExternalUserColor,
    setPaymentMethod,
    onSearch,
  } = props

  const isDisabled = useMemo(() => {
    if (Object.values(selectedFilters).every((selected) => selected !== '')) {
      return false
    }

    return true
  }, [selectedFilters])

  return (
    <>
      <div className='row align-items-end'>
        <div className='col-lg-10'>
          <div className='row'>
            <div className='col-lg-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Мерчант</label>
              <MerchantFilter
                changeSelect={({ value }) => setMerchant(value[0])}
                value={[selectedFilters.merchantToken]}
              />
            </div>
            <div className='col-lg-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Направление платежа</label>
              <Select
                onChange={(e) => setDirection(e?.value as string)}
                options={PAYMENT_DIRRECTION_OPTIONS}
                placeholder={'Выберите направление платежа'}
                styles={SelectStyles(theme)}
              />
            </div>
            <div className='col-lg-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Цвет клиента</label>
              <Select
                onChange={(e) => setExternalUserColor(e?.value as string)}
                options={CLIENT_COLOR_OPTIONS}
                placeholder={'Выберите цвет клиента'}
                styles={SelectStyles(theme)}
              />
            </div>
            <div className='col-lg-3'>
              <label className='fs-6 form-label fw-bolder text-dark'>Платежный метод</label>
              <Select
                onChange={(e) => setPaymentMethod(e?.value as string)}
                options={PAYMENT_METHODS_OPTIONS}
                placeholder={'Выберите платежный метод'}
                styles={SelectStyles(theme)}
              />
            </div>
          </div>
        </div>
        <div className='col-lg-2'>
          <button className='btn btn-success me-5' onClick={onSearch} disabled={isDisabled}>
            Искать
          </button>
        </div>
      </div>
    </>
  )
}
