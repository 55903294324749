import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'

import { ROLE } from '../auth'
import { PrivateRoute } from '../auth/PrivateRoute'
import { ActiveStaff } from './routes/ActiveStaff'
import { BlockedStaff } from './routes/BlockedStaff'

const StaffPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='/active'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <ActiveStaff />
            </PrivateRoute>
          }
        />
        <Route
          path='/blocked'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <BlockedStaff />
            </PrivateRoute>
          }
        />
        <Route index element={<Navigate to='/active' />} />
      </Route>
    </Routes>
  )
}

export default StaffPage
