import { MenuItem } from './MenuItem'
import { usePageData } from '../../../core'
import { IsGranted } from '../../../../../app/modules/auth/PrivateRoute'
import { useAuth } from '../../../../../app/modules/auth'

export function MenuInner() {
  const { currentUser } = useAuth()
  let pageData = usePageData()

  return pageData.pageBreadcrumbs?.length ? (
    <>
      {pageData.pageBreadcrumbs.map((breadcrumb, i) => {
        // Return the element. Also pass key
        return breadcrumb.roles && IsGranted(breadcrumb.roles, currentUser) ? (
          <MenuItem key={i} title={breadcrumb.title} to={breadcrumb.path} />
        ) : (
          ''
        )
      })}
    </>
  ) : (
    <></>
  )
}
