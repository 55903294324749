import { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { convertToUTCByMSK, formatSum } from '../../../../utils'
import { ReportBlockItems } from '../core/_models'
import { ReportBlock } from './ReportBlock'
import ReportTicketsFilter from './ReportTicketsFilter'
import { reportTicketsAPI } from '../core/_requests'
import { ROLE } from '../../auth'
import { STATUS_COLOR } from '../../../../constants'
import { getDefaultDateRange } from '../../../../utils/getDefaultDateRange'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payment-tickets/active',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER],
  },
  {
    title: 'Завершённые',
    path: '/payment-tickets/finished',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER],
  },
  {
    title: 'Отчёт',
    path: '/payment-tickets/report',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const defaultBlocks: ReportBlockItems = {
  [new Date().getTime()]: [],
}

const ReportTickets = () => {
  const { startDate: defaultStartDate, endDate: defaultEndDate } = getDefaultDateRange()
  const [filters, setFilters] = useState<any>({
    dateStart: defaultStartDate,
    dateEnd: defaultEndDate,
    ticketType: 'payment',
    cashBoxIdList: [],
    merchantTokenList: [],
  })
  const [blocks, setBlocks] = useState(defaultBlocks)

  const updateReportBlocks = async (reset?: boolean) => {
    const { dateStart, dateEnd, cashBoxIdList, merchantTokenList, ...rest } = filters
    let query = {}

    if (reset) {
      query = {
        dateStart: convertToUTCByMSK(defaultStartDate),
        dateEnd: convertToUTCByMSK(defaultEndDate),
        ticketType: rest.ticketType,
        cashBoxIdList: [],
        merchantTokenList: [],
        paymentAdapterIdList: [],
      }

      setFilters({
        ...query,
        dateStart: defaultStartDate,
        dateEnd: defaultEndDate,
      })
    } else {
      query = {
        ...rest,
        dateStart: convertToUTCByMSK(dateStart),
        dateEnd: convertToUTCByMSK(dateEnd),
        cashBoxIdList: cashBoxIdList,
        merchantTokenList: merchantTokenList,
        paymentAdapterIdList: [],
      }
    }

    let response = await reportTicketsAPI(query)

    if (response.data.success && response.data.data) {
      setBlocks({
        [new Date().getTime()]: response.data.data,
      })
    }
  }

  useEffect(() => {
    updateReportBlocks()
  }, [])

  const blocksRender = Object.values(blocks)[0].map((statusData, index) => (
    <div className='col-xl-4' key={index}>
      <ReportBlock
        className='card-xl-stretch mb-xl-8'
        color={STATUS_COLOR[statusData.status]}
        status={statusData.status}
        sum={formatSum(statusData.sum)}
        count={statusData.ticketsCount}
        key={index}
      />
    </div>
  ))

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Отчёт</PageTitle>
        <div className='card-body border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Отчёт</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <ReportTicketsFilter
            initialFilter={filters}
            updateFilters={setFilters}
            callback={(reset?: boolean) => updateReportBlocks(reset)}
          />
        </div>
      </div>

      <div className='card'>
        <div className='row g-5 g-xl-8'>{blocksRender}</div>
      </div>
    </div>
  )
}

export default ReportTickets
