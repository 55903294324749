import { ClientRequisite } from '../../payout-tickets/core/_models'

export enum EClientFlags {
  white = 'white',
  red = 'red',
  yellow = 'yellow',
  green = 'green',
  blue = 'blue',
}

export interface PlatformInClient {
  id: number
  uuid: string
}

export interface MerchantInClient {
  token: string
  title: string
}

export interface OptionsInClient {
  color: string
}

export interface IClientItem {
  //external_user
  id?: number
  platform: PlatformInClient
  merchant: MerchantInClient
  externalId: string
  options: OptionsInClient
  token: string
  comment: string | null
  lastActivityDate: string | null
  createdAt: string
  requisites: Array<ClientRequisite>
}

export interface ClientEditRequest {
  color: string
  comment: string
}
