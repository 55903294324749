import axios from 'axios'
import { API_URL } from '../../../../constants'
import { ICashBoxesListRes } from '../../../../types'

export const fetchCashboxesList = () => {
  return axios.get<ICashBoxesListRes>(`${API_URL}/api/internal/cash-box/list`, {
    params: {
      enabled: true,
    },
  })
}