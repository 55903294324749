import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from './HeaderUserMenu'

const userAvatarClass = 'symbol-35px symbol-md-40px'

export const ProfileMenu = () => {
  return (
    <>
      <div
        className={clsx('cursor-pointer symbol', userAvatarClass)}
        data-kt-menu-trigger='click'
        data-kt-menu-attach='parent'
        data-kt-menu-placement='bottom-end'
      >
        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt='' />
      </div>
      <HeaderUserMenu />
    </>
  )
}
