export const CLIENT_COLOR_OPTIONS = [
  {
    label: 'Белый',
    value: 'white',
  },
  {
    label: 'Красный',
    value: 'red',
  },
  {
    label: 'Желтый',
    value: 'yellow',
  },
  {
    label: 'Зеленый',
    value: 'green',
  },
  {
    label: 'Синий',
    value: 'blue',
  },
]
