import { formatSum } from '../../../../../../../utils'
import { ISummaryProps } from './Summary.types'

export const Summary = (props: ISummaryProps) => {
  const { data } = props

  return (
    <>
      {data && data.payment && (
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='card-rounded w-100'>
                <h2 className='m-0 fw-bold fs-2'>Пополнения</h2>
                <div className='d-flex flex-column pt-8'>
                  <span className='fw-semibold fs-3'>
                    {`Всего тикетов: ${data.payment.ticketCount}, сумма ${formatSum(
                      data.payment.ticketAmount
                    )}`}
                  </span>
                  <span className='fw-semibold fs-3'>
                    {`Завершено: ${data.payment.ticketCompletedCount}, сумма ${formatSum(
                      data.payment.ticketCompletedAmount
                    )}`}
                  </span>
                  <span className='fw-semibold fs-3'>
                    Отменено: {data.payment.ticketCanceledCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data && data.payout && (
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-body'>
              <div className='card-rounded w-100'>
                <h2 className='m-0 fw-bold fs-2'>Выплаты</h2>
                <div className='d-flex flex-column pt-8'>
                  <span className='fw-semibold fs-3'>
                    {`Всего тикетов: ${data.payout.ticketCount}, сумма ${formatSum(
                      data.payout.ticketAmount
                    )}`}
                  </span>
                  <span className='fw-semibold fs-3'>
                    {`Завершено: ${data.payout.ticketCompletedCount}, сумма ${formatSum(
                      data.payout.ticketCompletedAmount
                    )}`}
                  </span>
                  <span className='fw-semibold fs-3'>
                    Отменено: {data.payout.ticketCanceledCount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
