import axios from 'axios'
import { API_URL } from '../../../../constants'
import { IGeneralErrorObject } from '../../../../types'

export interface IPostPrioritiesReqQuery {
  priorities: any[]
  merchantToken: string
  paymentMethodType: string
  currency: string
  direction: string
  externalUserFlag: string
}

export interface IPostPrioritiesRes {
  success: boolean
  totalRows: number
  errors: Partial<IGeneralErrorObject>
}

export const postAdapterPriorities = (query: IPostPrioritiesReqQuery) =>
  axios.post<IPostPrioritiesRes>(`${API_URL}/api/internal/payment-adapter/priority/`, query)
