import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useWindowSize } from '../../../../../../utils/hooks/useWindowSize'

export interface IAdapter {
  amountRequired: boolean
  createdAt: string
  enabled: boolean
  id: string
  minDeposit: number
  name: string
  updatedAt: string
  usingExternalGateway: boolean
}

interface PaymentAdapterCardProps {
  item: IAdapter
  onToggle: (e: ChangeEvent<HTMLInputElement>, item: any) => void
  setAdapter: Dispatch<SetStateAction<never[]>>
}

function PaymentAdapterCard({ item, onToggle, setAdapter }: PaymentAdapterCardProps) {
  const [sum, setSum] = useState<number>(item?.minDeposit || 0)
  const { width } = useWindowSize()

  useEffect(() => {
    setSum(item?.minDeposit || 0)
  }, [item])

  return (
    <div className='flex' key={item?.id}>
      <div className='d-flex align-items-center' style={{ height: '76px' }}>
        <div className={`${width <= 540 ? 'w-60px me-4' : 'w-90px me-10'}  me-10`}>
          <input
            name='minDeposit'
            className='form-control form-control-solid'
            placeholder='Введите сумму'
            value={sum}
            onChange={(e) => {
              if (!Number.isNaN(Number(e.target.value))) {
                setSum(Number(e.target.value))
                setAdapter((prev: any) =>
                  prev.map((el: any) =>
                    el.name === item.name ? { ...el, minDeposit: Number(e.target.value) } : el
                  )
                )
              }
            }}
          />
        </div>
        <div className='d-flex form-check form-check-solid form-switch fv-row'>
          <input
            style={{ cursor: 'pointer' }}
            className='form-check-input w-45px h-30px'
            type='checkbox'
            checked={item?.enabled}
            onChange={(e) => onToggle(e, item)}
          />
        </div>
      </div>
    </div>
  )
}

export default PaymentAdapterCard
