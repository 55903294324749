import { PageLink } from '../../../_metronic/layout/core'
import { ROLE } from '../auth'

export const CLIENTS_PAGE_BREADCRUMBS: Array<PageLink> = [
  {
    title: 'Инфо',
    path: '/client/info/__token__',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
  },
  {
    title: 'Настройки',
    path: '/client/edit/__token__',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER],
  },
  {
    title: 'Транзакции',
    path: '/client/transactions/__token__',
    isSeparator: false,
    isActive: false,
    roles: [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR],
  },
]
