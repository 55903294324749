import axios from 'axios'
import { PayoutTicketActionResponse } from './_models'
import { API_URL } from '../../../../constants'

export const IN_PROGRESS_TOKEN = `${API_URL}/api/internal/payout/in_progress/__token__`
export const FINISH_TOKEN = `${API_URL}/api/internal/payout/complete/__token__`
export const CANCEL_TOKEN = `${API_URL}/api/internal/payout/decline/__token__`

export function inProgressTicketAPI(token: string) {
  return axios.post<PayoutTicketActionResponse>(IN_PROGRESS_TOKEN.replace('__token__', token))
}

export function finishTicketAPI(token: string, commission?: string) {
  return axios.post<PayoutTicketActionResponse>(
    FINISH_TOKEN.replace('__token__', token),
    commission && {
      commission,
    }
  )
}

export function cancelTicketAPI(token: string) {
  return axios.post<PayoutTicketActionResponse>(CANCEL_TOKEN.replace('__token__', token))
}
