import { ClientsSearch } from '../../../../../components/ClientsSearch'
import '../../../../assets/css/pagination.css'

export const ClientsList = () => {
  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Клиенты</span>
          </h3>
        </div>
      </div>

      <div className='card'>
        <div className='card-body py-3'>
          <ClientsSearch />
        </div>
      </div>
    </div>
  )
}
