import axios from 'axios'
import {AuthModel, UserModelResponse} from './_models'
import {env} from "../../../../env";

const API_URL = env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/security/user-data`
export const LOGIN_URL = `${API_URL}/api/security/login`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    'login': email,
    'password': password,
  })
}

export function getUserByToken(token: string) {
  return axios.get<UserModelResponse>(GET_USER_BY_ACCESSTOKEN_URL)
}
