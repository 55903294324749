import React from 'react'
import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { ROLE } from '../auth'
import { PrivateRoute } from '../auth/PrivateRoute'
import { Employees } from './routes/Employees'
import { Requisites } from './routes/Requisites'
import { PushAndSms } from './routes/PushAndSms'

export const Logs: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='/employees'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <Employees />
            </PrivateRoute>
          }
        />
        <Route
          path='/requisites'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <Requisites />
            </PrivateRoute>
          }
        />
        <Route
          path='/push-and-sms'
          element={
            <PrivateRoute roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}>
              <PushAndSms />
            </PrivateRoute>
          }
        />

        <Route index element={<Navigate to='/employees' />} />
      </Route>
    </Routes>
  )
}
