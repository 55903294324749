import axios from 'axios'
import { ADD_NEW_EMPLOYEE_URL } from './consts'
import { INewEmployeeResponse } from './types'

export const addNewEmployee = (
  uuid: string,
  password: string,
  role: string,
  cashboxId?: number
) => {
  return axios.post<INewEmployeeResponse>(ADD_NEW_EMPLOYEE_URL, {
    uuid,
    password,
    role,
    cashboxId,
    enabled: true,
  })
}
