import axios from 'axios'
import { ROLE, UserModel } from '../../auth'
import { getUserRolesByHierarchy } from '../../auth/PrivateRoute'
import { GET_EMPLOYEE_LIST_TICKETS, GET_LIST_TICKETS } from './consts'
import { IParamsTicketsListRequest, ITicketsListResponse } from './ticketsList.types'
import qs from 'qs'

export const getTicketsList = (
  params: Partial<IParamsTicketsListRequest>,
  currentUser?: UserModel
) => {
  if (!params.perPage) {
    params.perPage = 99
  }

  if (!params.page) {
    params.page = 1
  }

  const url = GET_LIST_TICKETS

  return axios.get<ITicketsListResponse>(url, {
    params: params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    },
  })
}
