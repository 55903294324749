import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { ROLE, useAuth } from '../../../../../app/modules/auth'
import { IsGranted } from '../../../../../app/modules/auth/PrivateRoute'

const SidebarMenuMain = () => {
  const { currentUser } = useAuth()

  return (
    <>
      {IsGranted([ROLE.ROLE_MANAGER], currentUser) ? (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Служебные</span>
          </div>
        </div>
      ) : (
        ''
      )}
      <SidebarMenuItemWithSub
        to='/staff/'
        title='Сотрудники'
        icon='security-user'
        fontIcon='bi-person'
        roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
      >
        <SidebarMenuItem
          to='/staff/active'
          title='Активные'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
        <SidebarMenuItem
          to='/staff/blocked'
          title='Заблокированные'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/settings/'
        title='Настройки'
        icon='gear'
        fontIcon='bi-gear'
        roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER]}
      >
        <SidebarMenuItem
          to='/settings/requisites'
          title='Реквизиты'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER]}
        />
        <SidebarMenuItem
          to='/settings/cashboxes-priorities'
          title='Приоритет касс'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
        <SidebarMenuItem
          to='/settings/payment-adapters'
          title='Платежные адаптеры'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
        <SidebarMenuItem
          to='/settings/payment-adapter-priorities'
          title='Приоритет платежных адаптеров'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/logs/'
        title='Логи'
        icon='clipboard'
        fontIcon='bi-clipboard'
        roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
      >
        <SidebarMenuItem
          to='/logs/employees'
          title='Сотрудники'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
        <SidebarMenuItem
          to='/logs/requisites'
          title='Реквизиты'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
        <SidebarMenuItem
          to='/logs/push-and-sms'
          title='Push и SMS'
          hasBullet={true}
          roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER]}
        />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Общие</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/client/list'
        title='Клиенты'
        icon='profile-circle'
        roles={[ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR]}
      />
      <SidebarMenuItemWithSub
        to='/payment-tickets/'
        title='Тикеты пополнения'
        icon='double-up'
        fontIcon='bi-person'
        roles={[
          ROLE.ROLE_ADMIN,
          ROLE.ROLE_MANAGER,
          ROLE.ROLE_CASHIER,
          ROLE.ROLE_SENIOR_CASHIER,
          ROLE.ROLE_SENIOR_OPERATOR,
          ROLE.ROLE_OPERATOR,
        ]}
      >
        <SidebarMenuItem
          to='/payment-tickets/active'
          title='Активные'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_CASHIER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
            ROLE.ROLE_OPERATOR,
          ]}
        />
        <SidebarMenuItem
          to='/payment-tickets/finished'
          title='Завершённые'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_CASHIER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
            ROLE.ROLE_OPERATOR,
          ]}
        />
        <SidebarMenuItem
          to='/payment-tickets/report'
          title='Отчёты'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
          ]}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/payout-tickets/'
        title='Тикеты выплат'
        icon='double-down'
        fontIcon='bi-person'
        roles={[
          ROLE.ROLE_ADMIN,
          ROLE.ROLE_MANAGER,
          ROLE.ROLE_SENIOR_CASHIER,
          ROLE.ROLE_CASHIER,
          ROLE.ROLE_SENIOR_OPERATOR,
          ROLE.ROLE_OPERATOR,
        ]}
      >
        <SidebarMenuItem
          to='/payout-tickets/active'
          title='Активные'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
            ROLE.ROLE_OPERATOR,
          ]}
        />
        <SidebarMenuItem
          to='/payout-tickets/finished'
          title='Завершённые'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
            ROLE.ROLE_OPERATOR,
          ]}
        />
        <SidebarMenuItem
          to='/payout-tickets/report'
          title='Отчёты'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
          ]}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/reports/'
        title='Отчёты'
        icon='book'
        fontIcon='bi-person'
        roles={[
          ROLE.ROLE_ADMIN,
          ROLE.ROLE_MANAGER,
          ROLE.ROLE_SENIOR_CASHIER,
          ROLE.ROLE_SENIOR_OPERATOR,
        ]}
      >
        <SidebarMenuItem
          to='/reports/payment'
          title='Отчёт по пополнениям'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
          ]}
        />
        <SidebarMenuItem
          to='/reports/payout'
          title='Отчёт по выплатам'
          hasBullet={true}
          roles={[
            ROLE.ROLE_ADMIN,
            ROLE.ROLE_MANAGER,
            ROLE.ROLE_SENIOR_CASHIER,
            ROLE.ROLE_SENIOR_OPERATOR,
          ]}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export { SidebarMenuMain }
