import { AxiosError } from 'axios'
import { AuthModel } from './_models'
import Swal from 'sweetalert2'
import { LOGIN_URL } from './_requests'
import { handleAPIErrors } from '../../../common/ErrorHandler'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'

  axios.interceptors.request.use(
    (config: { headers: { 'x-api-key': string } }) => {
      const auth = getAuth()

      if (auth && auth.auth_token) {
        config.headers['x-api-key'] = auth.auth_token
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => response,
    (error: AxiosError) => {
      const status = error.response?.status
      const url = error.config.url

      if (status === 403) {
        Swal.fire('У вас нет доступа к данному действию', '', 'warning')
      }

      if (status === 401) {
        removeAuth()

        if (window.location.pathname !== '/auth') {
          window.location.reload()
        }
      }

      if (status !== 400 && url !== LOGIN_URL && !url?.includes('/internal/payment-adapter/')) {
        Swal.fire('Ошибка', 'Произошла ошибка на сервере: ' + error.message, 'error')
      }

      if (status === 400 && url !== LOGIN_URL) {
        handleAPIErrors(error.response?.data.errors, status)
      }

      return error.response
    }
  )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
