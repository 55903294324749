import axios from 'axios'
import {
  FETCH_LOGIN_EVENTS_LOGS_URL,
  FETCH_REQUISITE_HISTORY_LOGS_URL,
  FETCH_USER_EVENTS_LOGS_URL,
} from './consts'
import {
  IFetchLoginEventsParams,
  IFetchLoginEventsResponse,
  IFetchRequisiteHistoryParams,
  IFetchRequisiteHistoryResponse,
  IFetchUserEventsParams,
  IFetchUserEventsResponse,
} from './logs.types'

export const fetchLoginEventsLogs = (params: IFetchLoginEventsParams) => {
  return axios.get<IFetchLoginEventsResponse>(`${FETCH_LOGIN_EVENTS_LOGS_URL}`, {
    params: {
      ...params,
      perPage: 20,
    },
  })
}

export const fetchUserEventsLogs = (params: IFetchUserEventsParams) => {
  return axios.get<IFetchUserEventsResponse>(`${FETCH_USER_EVENTS_LOGS_URL}`, {
    params: {
      ...params,
      perPage: 20,
    },
  })
}

export const fetchRequisitesHistoryLogs = (params: IFetchRequisiteHistoryParams) => {
  if (params.xlsx) {
    return axios.get(`${FETCH_REQUISITE_HISTORY_LOGS_URL}`, {
      params: {
        ...params,
        perPage: 100,
      },
      responseType: 'blob',
    })
  } else {
    return axios.get<IFetchRequisiteHistoryResponse>(`${FETCH_REQUISITE_HISTORY_LOGS_URL}`, {
      params: {
        ...params,
        perPage: 100,
      },
    })
  }
}
