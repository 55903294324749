import { useState } from 'react'
import Select from 'react-select'

import { SelectOptionString, SelectData } from './models'
import { SelectStyles } from '../../../components/Select'
import { ROLE, useAuth } from '../../modules/auth'
import { IsGranted } from '../../modules/auth/PrivateRoute'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'ticketType'

const TicketTypeFilter = ({
  changeSelect,
  isMultiple,
  value,
}: {
  changeSelect: (selectData: SelectData) => void
  initialFilter?: Array<number>
  isMultiple?: boolean
  value: string
}) => {
  const { mode: theme } = useThemeMode()
  const { currentUser } = useAuth()
  const isRoleOperator = IsGranted([ROLE.ROLE_SENIOR_OPERATOR, ROLE.ROLE_OPERATOR], currentUser)

  const [options, setOptions] = useState<Array<SelectOptionString>>([
    {
      label: 'MANUAL',
      value: 'MANUAL',
    },
    ...(!isRoleOperator
      ? [
          {
            label: 'PLATFORM',
            value: 'PLATFORM',
          },
          {
            label: 'SMS',
            value: 'SMS',
          },
          {
            label: 'MERCHANT',
            value: 'MERCHANT',
          },
        ]
      : []),
  ])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? ''
      : !isMultiple
      ? selectedData.value
      : selectedData.map((item: SelectOptionString) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return (
    <Select
      value={options.filter((o: SelectOptionString) => value === o.value) || ''}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите источник создания'}
      styles={SelectStyles(theme)}
    />
  )
}

export default TicketTypeFilter
