import axios from 'axios'
import { API_URL } from '../../../../constants'
import { IGeneralErrorObject } from '../../../../types'
import { Currency } from '../core/_models'

export interface IGetAdapterPrioritiesListReqQuery {
  merchantToken: string
  paymentMethodType: string
  currency: string
  direction: string
}

export interface IAdapterPriority {
  id: number
  merchant: {
    token: string
    title: string
    enabled: boolean
  }
  paymentMethod: {
    type: string
    enabled: boolean
  }
  paymentAdapter: {
    name: string
    enabled: boolean
    type: 'LOCAL'
  }
  currency: Currency
  direction: 'PAYMENT' | 'PAYOUT'
  priority: number
  enabled: boolean
}

export interface IGetAdapterPrioritiesListRes {
  paymentAdapterPriorities: IAdapterPriority[]
  success: boolean
  totalRows: number
  errors: Partial<IGeneralErrorObject>
}

export const getPaymentAdapterPriorities = (params?: IGetAdapterPrioritiesListReqQuery) =>
  axios.get(`${API_URL}/api/internal/payment-adapter/priority/list`, {
    params,
  })
