import React from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import ActiveTickets from './components/ActiveTickets'
import FinishedTickets from './components/FinishedTickets'
import ReportTickets from './components/ReportTickets'
import { ROLE } from '../auth'
import { PrivateRoute } from '../auth/PrivateRoute'

const PaymentTicketsPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='active'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_CASHIER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_SENIOR_OPERATOR,
                ROLE.ROLE_OPERATOR,
              ]}
            >
              <ActiveTickets />
            </PrivateRoute>
          }
        />
        <Route
          path='finished'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_CASHIER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_SENIOR_OPERATOR,
                ROLE.ROLE_OPERATOR,
              ]}
            >
              <FinishedTickets />
            </PrivateRoute>
          }
        />
        <Route
          path='report'
          element={
            <PrivateRoute
              roles={[
                ROLE.ROLE_ADMIN,
                ROLE.ROLE_MANAGER,
                ROLE.ROLE_SENIOR_CASHIER,
                ROLE.ROLE_SENIOR_OPERATOR,
              ]}
            >
              <ReportTickets />
            </PrivateRoute>
          }
        />
        <Route index element={<Navigate to='/active' />} />
      </Route>
    </Routes>
  )
}

export default PaymentTicketsPage
