import { useIntl } from 'react-intl'

import { convertUTCtoMSK, formatSum } from '../../../../../../../utils'
import { ITransaction } from '../../api/transactions.types'

export const TransactionTableRow = (props: { data: ITransaction }) => {
  const { data: transaction } = props
  const intl = useIntl()

  return (
    <tr>
      <td>{convertUTCtoMSK(transaction.created_at)}</td>
      <td>{formatSum(transaction.amount)}</td>
      <td>{transaction.cashbox_title}</td>
      <td>{transaction.employee}</td>
      <td>{intl.formatMessage({ id: `transaction.status.${transaction.status}` })}</td>
      <td>{props.data.type}</td>
    </tr>
  )
}
