import { format } from 'date-fns'

/**
 * @param dateTime: Date - any timeZome
 *
 * To ensure that a user, regardless of their timezone, selects a time as if they were in the 'Europe/Moscow' timezone
 *
 * User from Georgia GMT+0400 when selects a time, 08:00 will get result as if he in the 'Europe/Moscow' timezone GMT+0300 or UTC 05:00, NOT UTC 04:00 if we convert in from GMT+0400 to UTC.
 *
 * @returns Date in UTC string
 */
export const convertToUTCByMSK = (dateTime: Date): string => {
  const moscowTz = format(dateTime, 'EEE LLL dd yyyy HH:mm ') + 'GMT+0300'
  return new Date(moscowTz).toISOString()
}
