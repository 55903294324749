import { useEffect, useState } from 'react'
import Select from 'react-select'

import { SelectData, SelectOptionString } from './models'
import { PaymentTicketProcessType } from '../../modules/payment-tickets/core/_models'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'payment_ticket_process_type'

const PaymentTicketProcessTypeFilter = ({
  changeSelect,
  value,
  isMultiple,
}: {
  changeSelect: (selectData: SelectData) => void
  value: string[]
  isMultiple?: boolean
}) => {
  const { mode: theme } = useThemeMode()
  const [options, setOptions] = useState<Array<SelectOptionString>>([])
  const [loading, setLoading] = useState(true)

  const updateListTypes = () => {
    let types: Array<SelectOptionString> = []

    Object.keys(PaymentTicketProcessType).forEach((value) => {
      const item = {
        value: value,
        label: value,
        isFixed: false,
      }

      types.push(item)
    })

    setOptions(types)

    setLoading(false)
  }

  useEffect(() => {
    updateListTypes()
  }, [])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? []
      : !isMultiple
      ? [selectedData.value]
      : selectedData.map((item: SelectOptionString) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return loading ? (
    <></>
  ) : (
    <Select
      value={options.filter((o: SelectOptionString) => value?.find((sv) => sv === o.value)) || ''}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите тип зачисления'}
      styles={SelectStyles(theme)}
    />
  )
}

export default PaymentTicketProcessTypeFilter
