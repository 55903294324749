import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, ROLE, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  routeByRoleRedirect: string
  setRouteByRole: (route: string) => void
}

const ROLE_REDIRECTS = new Map<ROLE, string>()

ROLE_REDIRECTS.set(ROLE.ROLE_ADMIN, '/staff/active')
ROLE_REDIRECTS.set(ROLE.ROLE_MANAGER, '/settings/requisites')
ROLE_REDIRECTS.set(ROLE.ROLE_SENIOR_CASHIER, '/payment-tickets/report')
ROLE_REDIRECTS.set(ROLE.ROLE_CASHIER, '/payout-tickets/active')

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  routeByRoleRedirect: '/',
  setRouteByRole: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [routeByRoleRedirect, setRouteByRoleRedirect] = useState<string>('/')

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  const setRouteByRole = (route: string) => {
    setRouteByRoleRedirect(route)
  }

  const state = useMemo(
    () => ({
      auth,
      saveAuth,
      currentUser,
      setCurrentUser,
      logout,
      routeByRoleRedirect,
      setRouteByRole,
    }),
    [auth, saveAuth, currentUser, setCurrentUser, logout, routeByRoleRedirect, setRouteByRole]
  )

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, currentUser, setCurrentUser, setRouteByRole } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const { data } = await getUserByToken(apiToken)

          if (data) {
            setCurrentUser(data.userData)
          }
        }
      } catch (error) {
        console.error(error)
        logout()
        // if (!didRequest.current) {
        //   logout()
        // }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.auth_token) {
      requestUser(auth.auth_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentUser) {
      let routeRedirect: string = ''

      currentUser.roles.forEach((role) => {
        if (ROLE_REDIRECTS.has(role)) {
          routeRedirect = `${ROLE_REDIRECTS.get(role)}`
        }
      })

      setRouteByRole(routeRedirect)
    }
  }, [currentUser])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
