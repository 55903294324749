import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { getClientByToken } from '../../core/_requests'
import { ROLE, useAuth } from '../../../auth'
import { IClientItem } from '../../core/_models'
import { CLIENTS_PAGE_BREADCRUMBS } from '../../ClientsPage.consts'

import '../../../../assets/css/pagination.css'
import { IsGranted } from '../../../auth/PrivateRoute'

const breadCrumbs = [...CLIENTS_PAGE_BREADCRUMBS]

export const ClientInfo = () => {
  const { currentUser } = useAuth()
  const { token } = useParams()
  const [client, setClient] = useState<IClientItem | undefined>(undefined)

  breadCrumbs.forEach((value, index) => {
    if (value.title === 'Инфо') {
      breadCrumbs[index].isActive = true
    }
    breadCrumbs[index].path = breadCrumbs[index].path.replace('__token__', token + '')
  })

  const getClientInfo = async () => {
    let response = await getClientByToken(token + '', currentUser)

    if (response.data.success && response.data.externalUser) {
      setClient(response.data.externalUser)
    }
  }

  useEffect(() => {
    getClientInfo()
  }, [false])

  return !client ? (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs}>Клиент инфо</PageTitle>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column text-center'>
            <span className='card-label fw-bold fs-3 mb-1'>Идёт загрузка данных клиента...</span>
          </h3>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={breadCrumbs}>Клиент инфо</PageTitle>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Клиент: {token}</span>
          </h3>
        </div>
      </div>

      <div className='d-flex flex-column flex-md-row gap-2'>
        <div className='card w-100'>
          <div className='card-body p-9'>
            {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
              <div className='fs-6 d-flex justify-content-between mb-4'>
                <div className='fw-bold'>Флаг</div>
                <div className='d-flex'>{client.options?.color}</div>
              </div>
            )}

            {client.id ? (
              <div className='fs-6 d-flex justify-content-between mb-4'>
                <div className='fw-bold'>user id</div>
                <div className='d-flex'>{client.id}</div>
              </div>
            ) : (
              ''
            )}

            <div className='fs-6 d-flex justify-content-between my-4'>
              <div className='fw-bold'>external user id</div>
              <div className='d-flex'>{client.externalId}</div>
            </div>

            <div className='separator separator-dashed'></div>

            {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
              <div className='fs-6 d-flex justify-content-between my-4'>
                <div className='fw-bold'>merchant id</div>
                <div>{client.merchant.title}</div>
              </div>
            )}

            <div className='separator separator-dashed'></div>

            <div className='fs-6 d-flex justify-content-between my-4'>
              <div className='fw-bold'>Создан</div>
              <div className='d-flex'>{format(parseISO(client.createdAt), 'dd.MM.yyyy HH:mm')}</div>
            </div>

            <div className='fs-6 d-flex justify-content-between my-4'>
              <div className='fw-bold'>Последняя активность</div>
              <div className='d-flex'>
                {client.lastActivityDate
                  ? format(parseISO(client.lastActivityDate), 'dd.MM.yyyy HH:mm')
                  : ''}
              </div>
            </div>

            {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
              <>
                <div className='separator separator-dashed'></div>

                <div className='fs-6 d-flex justify-content-between my-4'>
                  <div className='fw-bold me-20'>Комментарий</div>
                  <div className='d-flex text-end text-break'>{client.comment}</div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='card w-100'>
          <div className='card-header pb-0'>
            <div className='card-title'>
              <h3 className='m-0'>Карты клиента</h3>
            </div>
          </div>
          <div className='card-body'>
            {client.requisites.map((requisite) => {
              return (
                <>
                  <div className='d-flex flex-column gap-2 fs-6 pb-2'>
                    <div>
                      <span className='fw-bold'>Номер карты:</span> {requisite.identifier}
                    </div>
                    <div>
                      <span className='fw-bold'>Тип реквизита:</span>{' '}
                      {requisite.requisiteType ?? '-'}
                    </div>
                    <div>
                      <span className='fw-bold'>Валюту:</span> {requisite.currency ?? '-'}
                    </div>
                    <div>
                      <span className='fw-bold'>Банк-эквайер:</span>{' '}
                      {requisite.acquiringBank ?? '-'}
                    </div>
                    <div>
                      <span className='fw-bold'>Держатель:</span> {requisite.cardholder ?? '-'}
                    </div>
                    <div>
                      <span className='fw-bold'>Комментарий:</span> {requisite.comment ?? '-'}
                    </div>
                  </div>
                  <div className='separator separator-dashed mb-4'></div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
