import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { ru } from 'date-fns/locale'

import 'react-datepicker/dist/react-datepicker.css'

const DateTimeFilter = (props: {
  updateTime: (dateTime: Date | null) => void
  withTime?: boolean
  placeholder?: string
  defaultDate?: Date
  onCalendarOpen?: () => void
  onCalendarClose?: () => void
  shouldCloseOnSelect?: boolean
}) => {
  const input = (
    <input
      className='form-control form-control-solid ps-12 flatpickr-input'
      placeholder='Дата'
      autoComplete='off'
      type='text'
    />
  )

  const [dateTime, setDateTime] = useState<Date | null>(props.defaultDate || null)

  const setDate = (date: Date | null) => {
    setDateTime(date)
  }

  useEffect(() => {
    props.updateTime(dateTime)
  }, [dateTime])

  return (
    <div className='position-relative d-flex align-items-center'>
      <div className='symbol symbol-20px me-4 position-absolute ms-4 calendar-icon-datepicker'>
        <i className='fa fa-calendar-alt'></i>
      </div>
      <DatePicker
        placeholderText={props.placeholder}
        selected={dateTime}
        onChange={setDate}
        customInput={input}
        locale={ru}
        showTimeInput={props.withTime}
        // timeFormat="HH:mm"
        dateFormat={props.withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}
        onCalendarOpen={props.onCalendarOpen}
        onCalendarClose={props.onCalendarClose}
        shouldCloseOnSelect={props.shouldCloseOnSelect || false}
      />
    </div>
  )
}

export default DateTimeFilter
