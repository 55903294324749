import axios from 'axios'
import {StaffListResponse} from './_models'
import {env} from "../../../../env";

const API_URL = env.REACT_APP_API_URL

export const GET_LIST_STAFF = `${API_URL}/api/internal/employee/list`

export function getListStaff(enabled = true) {
  return axios.get<StaffListResponse>(GET_LIST_STAFF, {
    params: {
      perPage: 99,
      page: 1,
      enabled: enabled,
    }
  })
}