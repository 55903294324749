type TSum = string | number

interface IFormatSumOptions {
  locale: string
  minimumFractionDigits: number
  maximumFractionDigits: number
  useGrouping: boolean
  usePeriod: boolean
  withoutDecimals: boolean
}

const defaultOptions = {
  locale: 'ru-RU',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
  useGrouping: true,
  usePeriod: true,
  withoutDecimals: true,
}

export const formatSum = (sum: TSum, options: IFormatSumOptions = defaultOptions) => {
  const number = Number(sum)
  const formattedNumber = new Intl.NumberFormat(options.locale, {
    minimumFractionDigits: options.minimumFractionDigits,
    maximumFractionDigits: options.maximumFractionDigits,
    useGrouping: options.useGrouping,
  }).format(number)

  let result = formattedNumber

  if (options.withoutDecimals) {
    result = formattedNumber.endsWith('.00') ? formattedNumber.slice(0, -3) : formattedNumber
  }

  if (options.usePeriod) {
    result = formattedNumber.replace(',', '.')
  }

  return result
}
