import { API_URL } from '../../../../constants'

const LOGS_API = `${API_URL}/api/internal/logs`

export const FETCH_LOGIN_EVENTS_LOGS_URL = `${LOGS_API}/login-event`
export const FETCH_USER_EVENTS_LOGS_URL = `${LOGS_API}/user-event`

export const FETCH_REQUISITE_HISTORY_LOGS_URL = `${LOGS_API}/requisite-history`

export const GET_INCOMING_LOGS_URL = `${API_URL}/api/sms/incoming-log`
