import { ChangeEvent, useEffect, useState } from 'react'
import DateTimeRangeFilter, {
  DateFilterObject,
} from '../../../../../../common/filter/DateTimeRangeFilter'
import { IFiltersProps } from './Filters.types'

export const Filters = (props: IFiltersProps) => {
  const { initialFilter, updateFilter, callback } = props
  const [selected, setSelected] = useState<string>('payment|payout')

  useEffect(() => {
    updateFilter({
      ...initialFilter,
      type: selected.split('|'),
    })
  }, [selected])

  const onTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value)
  }

  const updateTime = (filterData: DateFilterObject) => {
    updateFilter({
      ...initialFilter,
      dateStart: filterData.dateStart,
      dateEnd: filterData.dateEnd,
    })
  }

  return (
    <div className='row d-flex align-items-center'>
      <DateTimeRangeFilter filter={initialFilter} updateTime={updateTime} className='w-auto' />
      <div className='w-auto mx-auto'>
        <label className='fs-6 form-label fw-bolder text-dark d-block'>Тип транзакций</label>
        <div className='form-check form-check-custom form-check-solid pb-2'>
          <input
            id='by-payment-radio'
            className='form-check-input'
            type='radio'
            value='payment'
            name='transactions-type-radio'
            onChange={onTypeChange}
          />
          <label className='form-check-label cursor-pointer' htmlFor='by-payment-radio'>
            Пополнения
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid pb-2'>
          <input
            id='by-payout-radio'
            className='form-check-input'
            type='radio'
            value='payout'
            name='transactions-type-radio'
            onChange={onTypeChange}
          />
          <label className='form-check-label cursor-pointer' htmlFor='by-payout-radio'>
            Выплаты
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            id='by-payment-payout-radio'
            className='form-check-input'
            type='radio'
            value='payment|payout'
            name='transactions-type-radio'
            onChange={onTypeChange}
            defaultChecked
          />
          <label className='form-check-label cursor-pointer' htmlFor='by-payment-payout-radio'>
            Пополнения и Выплаты
          </label>
        </div>
      </div>
      <div className='w-auto mt-2'>
        <button className='btn btn-success me-5' onClick={callback}>
          Фильтровать
        </button>
      </div>
    </div>
  )
}
