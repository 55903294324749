import {Navigate, useLocation} from "react-router-dom";
import {ROLE, UserModel} from "./core/_models";
import {useAuth} from "./core/Auth";


// #Роли сторонних пользователей(клиентов платежки)
// ROLE_SENIOR_OPERATOR: [ROLE_OPERATOR] #Старший оператор(сотрудник сторонней системы)
// ROLE_PLATFORM: [ROLE_SENIOR_OPERATOR] #Платформа(сторонняя система)
//
// #Внутренние роли
// ROLE_CASHIER: [ROLE_EMPLOYEE] #Кассир
// ROLE_SENIOR_CASHIER: [ROLE_CASHIER] #Старший кассир
// ROLE_MANAGER: [ROLE_SENIOR_CASHIER] #Менеджер
// ROLE_ADMIN: [ROLE_MANAGER, ROLE_ALLOWED_TO_SWITCH] #Администратор

const ROLES_HIERARCHY = {
    [ROLE.ROLE_SENIOR_OPERATOR]: [ROLE.ROLE_OPERATOR],
    [ROLE.ROLE_PLATFORM]: [ROLE.ROLE_SENIOR_OPERATOR],
    [ROLE.ROLE_CASHIER]: [ROLE.ROLE_EMPLOYEE],
    [ROLE.ROLE_SENIOR_CASHIER]: [ROLE.ROLE_CASHIER],
    [ROLE.ROLE_MANAGER]: [ROLE.ROLE_SENIOR_CASHIER],
    [ROLE.ROLE_ADMIN]: [ROLE.ROLE_MANAGER],

    [ROLE.ROLE_SMS_PROVIDER]: [],
    [ROLE.ROLE_PLATFORM_NOTIFICATOR]: [],
    [ROLE.ROLE_EMPLOYEE]: [],
    [ROLE.ROLE_OPERATOR]: [],
}

export function IsGranted(roles: Array<ROLE>, currentUser?: UserModel) {
    // const {currentUser} = useAuth()

    if(!currentUser){
        return false;
    }

    const allRolesCurrentUser = getUserRolesByHierarchy(currentUser)

    let userHasRequiredRole = false;

    roles.forEach(role => {
        if(allRolesCurrentUser.includes(role)){
            userHasRequiredRole = true;
        }
    })

    return userHasRequiredRole;
}

export const PrivateRoute = ({children, roles,}: { children: JSX.Element; roles: Array<ROLE>; }) => {
    const {currentUser} = useAuth()
    let location = useLocation();
    // const { isAuthenticated, user, loading } = useSelector(state => state.auth);

    if (!currentUser) {
        return <Navigate to="/auth" state={{ from: location }} />;
    }

    if (!IsGranted(roles, currentUser)) {
        return <Navigate to="/error/403" state={{ from: location }} />;
    }

    return children;
};

export function getUserRolesByHierarchy(user?: UserModel) : Array<ROLE>{
    if(!user){
        return []
    }

    let allRoles: Array<ROLE> = []

    //For every role - add they dependencies Roles
    user.roles.forEach(value => {
        allRoles.push(value)

        if(ROLES_HIERARCHY.hasOwnProperty(value)){
            addRolesHierarchyRecursively(allRoles, value)
        }
    })

    return allRoles;
}

function addRolesHierarchyRecursively(allRoles: Array<ROLE>, role: ROLE){
    ROLES_HIERARCHY[role].forEach(value => {
        //Add new role
        if(!allRoles.includes(value)){
            allRoles.push(value)
        }

        //Check dependency Roles
        if(ROLES_HIERARCHY.hasOwnProperty(value)){
            addRolesHierarchyRecursively(allRoles, value)
        }
    })
}