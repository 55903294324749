import * as Yup from 'yup'
import { INewEmployee } from './newEmployee.types'
import { ROLE } from '../../../auth'

export const NEW_EMPLOYEE_SCHEMA = Yup.object().shape({
  uuid: Yup.string().required('Обязательное поле'),
  password: Yup.string().required('Обязательное поле'),
  role: Yup.string().required('Обязательное поле'),
})

export const DEFAULT_NEW_EMPLOYEE_OBJ: INewEmployee = {
  uuid: '',
  password: '',
  role: '',
}

export const ROLES_FOR_NEW_EMPLOYEE = [
  ROLE.ROLE_ADMIN,
  ROLE.ROLE_CASHIER,
  ROLE.ROLE_SENIOR_CASHIER,
  ROLE.ROLE_MANAGER,
]
