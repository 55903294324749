import { useState } from 'react'
import Select from 'react-select'

import { SelectOptionString, SelectData } from './models'
import { SelectStyles } from '../../../components/Select'
import { useThemeMode } from '../../../_metronic/partials'

export const selectType = 'externalUserFlagType'

const ExternalUserFlagFilter = ({
  changeSelect,
  isMultiple,
  value,
}: {
  changeSelect: (selectData: SelectData) => void
  initialFilter?: Array<number>
  isMultiple?: boolean
  value: string
}) => {
  const { mode: theme } = useThemeMode()
  const [options, setOptions] = useState<Array<SelectOptionString>>([
    {
      label: 'white',
      value: 'white',
    },
    {
      label: 'red',
      value: 'red',
    },
    {
      label: 'yellow',
      value: 'yellow',
    },
    {
      label: 'green',
      value: 'green',
    },
    {
      label: 'blue',
      value: 'blue',
    },
  ])

  const handleChange = (selectedData: any) => {
    const values = !selectedData
      ? ''
      : !isMultiple
      ? selectedData.value
      : selectedData.map((item: SelectOptionString) => item.value)

    changeSelect({
      value: values,
      type: selectType,
    })
  }

  return (
    <Select
      value={options.filter((o: SelectOptionString) => value === o.value) || ''}
      onChange={handleChange}
      isClearable={options.some((v) => !v.isFixed)}
      isMulti={isMultiple}
      options={options}
      placeholder={'Выберите флаг'}
      styles={SelectStyles(theme)}
    />
  )
}

export default ExternalUserFlagFilter
