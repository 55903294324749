import { env } from '../../../env'
import axios from 'axios'
import { MerchantsListResponse } from './_rest_models'
import { IsGranted } from '../../modules/auth/PrivateRoute'
import { ROLE, UserModel } from '../../modules/auth'
import { ICashBoxesListRes, IPaymentAdapterListRes } from '../../../types'

const API_URL = env.REACT_APP_API_URL

export const GET_LIST_CASH_BOXES = `${API_URL}/api/internal/cash-box/list`
export const GET_LIST_PAYMENT_ADAPTER = `${API_URL}/api/internal/payment-adapter/list`
export const GET_EMPLOYEE_LIST_MERCHANTS = `${API_URL}/api/internal/merchant/list`
export const GET_LIST_MERCHANTS = `${API_URL}/api/merchant/list`

export function getListCashBoxesForSelect() {
  return axios.get<Partial<ICashBoxesListRes>>(GET_LIST_CASH_BOXES, {
    params: {
      perPage: 99,
      page: 1,
      enabled: true,
    },
  })
}

export function getListPaymentAdapterForSelect() {
  return axios.get<Partial<IPaymentAdapterListRes>>(GET_LIST_PAYMENT_ADAPTER, {
    params: {
      perPage: 99,
      page: 1,
      enabled: true,
    },
  })
}

export function getListMerchantsForSelect(currentUser?: UserModel) {
  const url = IsGranted([ROLE.ROLE_EMPLOYEE, ROLE.ROLE_MANAGER], currentUser)
    ? GET_EMPLOYEE_LIST_MERCHANTS
    : GET_LIST_MERCHANTS

  return axios.get<Partial<MerchantsListResponse>>(url, {
    params: {
      perPage: 99,
      page: 1,
      enabled: true,
    },
  })
}
