import { useMemo } from 'react'
import Select from 'react-select'
import { IFiltersProps } from './Filters.types'
import { SelectStyles } from '../../../../../../../components/Select'
import { PAYMENT_DIRRECTION_OPTIONS } from '../../../../../../../constants'
import MerchantFilter from '../../../../../../common/filter/MerchantFilter'
import { useThemeMode } from '../../../../../../../_metronic/partials'
import { Currency } from '../../../../core/_models'
import PaymentMethodsFilter from '../../../../../../common/filter/PaymentMethodsFilter'
import ExternalUserFlagFilter from '../../../../../../common/filter/ExternalUserFlagFilter'

export const Filters = (props: IFiltersProps) => {
  const { mode: theme } = useThemeMode()
  const {
    selectedFilters,
    setMerchant,
    setPaymentMethod,
    setCurrency,
    setDirection,
    setExternalUserFlag,
    onSearch,
  } = props

  const isDisabled = useMemo(() => {
    if (Object.values(selectedFilters).every((selected) => selected !== '')) {
      return false
    }

    return true
  }, [selectedFilters])

  return (
    <>
      <div className='row align-items-end'>
        <div className='col-lg-10'>
          <div className='row'>
            <div className='col-lg-4 mb-2'>
              <label className='fs-6 form-label fw-bolder text-dark'>Мерчант</label>
              <MerchantFilter
                changeSelect={({ value }) => setMerchant(value[0])}
                value={[selectedFilters.merchantToken]}
              />
            </div>
            <div className='col-lg-4 mb-2'>
              <label className='fs-6 form-label fw-bolder text-dark'>Платежный метод</label>
              <PaymentMethodsFilter
                value={[selectedFilters.paymentMethodType]}
                changeSelect={({ value }) => setPaymentMethod(value[0])}
              />
            </div>
            <div className='col-lg-4 mb-2'>
              <label className='fs-6 form-label fw-bolder text-dark'>Валюта</label>
              <Select
                onChange={(e) => setCurrency(e?.value as string)}
                options={Object.values(Currency).map((currency) => ({
                  value: currency,
                  label: currency,
                }))}
                placeholder={'Выберите валюту'}
                styles={SelectStyles(theme)}
              />
            </div>
            <div className='col-lg-4'>
              <label className='fs-6 form-label fw-bolder text-dark'>Направление платежа</label>
              <Select
                onChange={(e) => setDirection(e?.value as string)}
                options={PAYMENT_DIRRECTION_OPTIONS}
                placeholder={'Выберите направление платежа'}
                styles={SelectStyles(theme)}
              />
            </div>
            <div className='col-lg-4'>
              <label className='fs-6 form-label fw-bolder text-dark'>Флаг</label>
              <ExternalUserFlagFilter
                value={selectedFilters.externalUserFlag}
                changeSelect={({ value }) => setExternalUserFlag(value)}
              />
            </div>
          </div>
        </div>
        <div className='col-lg-2'>
          <button className='btn btn-success me-5' onClick={onSearch} disabled={isDisabled}>
            Искать
          </button>
        </div>
      </div>
    </>
  )
}
