declare global {
    interface Window {
        env: any
    }
}

type EnvType = {
    REACT_APP_API_URL: string,
    REACT_APP_SASS_PATH: string,
    REACT_APP_SASS_VARIABLES_PATH: string,
    PUBLIC_URL: string
    REACT_APP_TZ: string
}

export const env: EnvType = { ...process.env, ...window.env }